import {Item} from "./Types";
import React from 'react';


export type ArticleModalProps = {
    children: Item;
    setModal: (modal: boolean) => void;
}


export default function ArticleModalComponent({children, setModal}: ArticleModalProps) {
    const article = children;
    const modal = article.modal;
    
    if (!modal) 
        return null;


    const close = (event: any) => {
        if (event.target.classList.contains("ArticleModalWrapper")) {
            setModal(false);
        }
    }


    const open = () => {
        setModal(false);
        window.open(article.url, '_blank');
    }
    
    

    if (Array.isArray(modal)) {
        return <div className="ArticleModalWrapper" onClick={close}>
            <div className="ArticleModal">
                <span className="close" onClick={() => setModal(false)}>x</span>

                {article.url && <button onClick={open}>פתיחת הכתבה</button>}

                <header>
                    <h1>{article.title}</h1>
                    <p>{article.author} - {article.publishDate}</p>
                </header>

                <main>
                    {modal.map((item, index) => {
                        if (typeof item === "string")
                            return <p key={index}>{item}</p>
                        
                        if (item.type === "subtitle")
                            return <h2 key={index}>{item.data}</h2>

                        if (item.type === "image")
                            return <img className={`img ${item.max ? "img-max" : ""}`} key={index} src={item.data} alt={article.title}/>

                        return null;
                    })}
                </main>
            </div>
        </div>
    }


    return <div className="ArticleModalWrapper" onClick={close}>
        <div className="ArticleModal" style={{}}>
            <span className="close" onClick={() => setModal(false)}>x</span>
            
            <img className="img" src={modal} alt={article.title}/>
        </div>
    </div>
    
    
    
}
