import Navigation, {Footer} from "./Components/Navigation";
import ArticleBox from "./Components/ArticleBox";
import AboutBox from "./Components/AboutBox";
import Crisis from "./Components/Crisis";
import {Helmet} from "react-helmet";
import {Section} from "./Section";
import {Text} from "./Global";
import './App.scss';


export default function App() {
    const link = (url: string) => window.open(url);
    const description = "שירותי דוברות, ייעוץ תקשורתי, ניהול מאבקים ציבוריים וניהול משברים";

    return <>
        <Helmet>
            <title>שלו יחסי ציבור ואסטרטגיה</title>
            <meta name="description" content={description}/>
        </Helmet>

        <Navigation/>

        <div className="App">
            <Section name="Header" topText={Text.Header.companyName} title={Text.Header.companySlogan}>
                {/*<div className="Header-extra-div">*/}

                {/*</div>*/}
            </Section>

            <main>
                <Section name="About" title={Text.About.title}>
                    <div className="AboutBoxes">
                        {Text.About.boxes.map(({icon, title, text}, i) => (
                            // @ts-ignore
                            <AboutBox key={i} icon={icon || "example.svg"} title={title || "נוסיף כותרת בהמשך"} 
                                      text={text || "נוסיף טקסט בהמשך"}/>
                        ))}
                    </div>
                </Section>

                <Section name="CrisisManagement" title={Text.CrisisManagement.title} subtitle={Text.CrisisManagement.subtitle}>
                    <div className="CrisisBoxes">
                        {/*// @ts-ignore*/}
                        {Text.CrisisManagement.crisis.map(({title, client, text}, i) =>
                            // @ts-ignore
                            <Crisis key={i} title={title || "נוסיף כותרת בהמשך"} client={client || "נוסיף לקוח בהמשך"} icon={"example"}/>
                        )}
                    </div>
                </Section>

                <Section name="Struggles" title={Text.Struggles.title} subtitle={Text.Struggles.subtitle}>
                    <div className="CrisisBoxes StrugglesBoxes">
                        {/*// @ts-ignore*/}
                        {Text.Struggles.struggles.map(({title, client, text}, i) =>
                            // @ts-ignore
                            <Crisis key={i} title={title || "נוסיף כותרת בהמשך"} client={client || "נוסיף לקוח בהמשך"}
                                    icon={"example"}/>
                        )}
                    </div>
                </Section>

                <Section name="Articles" title={Text.Articles.title} subtitle={Text.Articles.subtitle}>
                    <div className="ArticlesBoxes">
                        {Text.Articles.articles.map(({title, subtitle, source, link, author}, i) =>
                            <ArticleBox key={i} title={title} link={link} source={source} subtitle={subtitle} author={author}/>
                        )}
                    </div>
                </Section>

                <Section name="Contact" title={Text.Contact.title}>

                    <div className="ContactContent">
                        <div>
                            <img src="/icons/phone.svg" alt="phone"/>
                            <span>{Text.Contact.phone}</span>
                        </div>

                        <div onClick={() => link(`mailto:${Text.Contact.email}`)}>
                            <img src="/icons/email.svg" alt="email"/>
                            <span>{Text.Contact.email}</span>
                        </div>

                        <div onClick={() => link(`mailto:${Text.Contact.email2}`)}>
                            <img src="/icons/email.svg" alt="email"/>
                            <span>{Text.Contact.email2}</span>
                        </div>

                        <button onClick={() => link(Text.Contact.whatsapp)} className="round-button whatsapp whatsapp-contact">
                            <span>דברו איתנו בוואטסאפ</span>
                            <img src="/other/whatsapp.png" alt="whatsapp"/>
                        </button>

                        <button className="round-button whatsapp call-us" onClick={() => link("tel:0547400740")}>
                            <span>התקשרו אלינו</span>
                            {/*<img src="/icons/phone-white.svg" alt="phone"/>*/}
                        </button>
                    </div>
                </Section>
            </main>

            <Footer/>
        </div>
    </>
}
