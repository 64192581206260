import {Client, Collection, CollectionList, CollectionListItem} from "../Collections/Types";
import {SmallSteps2024part1, SmallSteps2025} from "./SmallSteps";
import {AllergyCommunication2024} from "./Allergy";



const LungsDoctors: Client = {
    name: "האיגוד הישראלי לרפואת ריאות",
    url: "https://lungs.doctorsonly.co.il/",
    logo: "/other/lungs-doctors.png",
};


const DreamDoctors: Client = {
    name: "עמותת רופאי חלום",
    url: "https://dreamdoctors.org.il/he/",
    logo: "/other/dream-doctors.png",
    favicon: "/other/dream-doctors-favicon.png",
};


const Neurology: Client = {
    name: "האיגוד הנוירולוגי בישראל",
    url: "https://www.israel-neurology.co.il/",
    logo: "/other/neurology.png",
    favicon: "/other/neurology.ico",
}


const GrainImportersAssociation: Client = {
    name: "האיגוד הישראלי ליבואני התבואות",
    url: "",
    logo: "/other/grain-importers.png",
    favicon: "",
}


const MedicalDream: Collection = {
    title: "עמותת רופאי חלום",
    client: DreamDoctors,
    items: [
        {
            url: "https://allisrael.com/israel-s-dream-doctors-medical-clowns-bring-smiles-to-taiwanese-children-after-april-earthquake",
            title: "Israel's 'Dream Doctors' medical clowns bring smiles to Taiwanese children after April earthquake",
            publishDate: "05/06/2024",
            author: "All Israel News Staff",
            image: "https://res.cloudinary.com/hb0stl6qx/image/upload/w_900,c_scale,q_auto,f_auto,dpr_auto/v1717587892/AIN/Screenshot_2024-06-05_at_14.43.31.png",
            type: "article",
            english: true
        },
        {
            url: "https://www.jpost.com/health-and-wellness/mind-and-spirit/article-804167",
            title: "Medical clowns: Israel's Dream Doctors use therapeutic clowning for evacuees",
            publishDate: "01/06/2024",
            author: "PATRICIA GOLAN",
            image: "https://images.jpost.com/image/upload/q_auto/c_fill,g_faces:center,h_537,w_822/600769",
            type: "article",
            english: true
        },
        {
            url: "https://omny.fm/shows/editcontent/8107dafc-1183-49b2-9f08-b17e009ca837",
            title: "הליצן הרפואי מניר עוז על הקושי מאחורי הצחוק",
            image: "https://www.omnycontent.com/d/clips/6dcbc33f-1fb6-49de-9ae2-ad8a00c01523/031bb697-72dd-44a4-92f3-ae52009bdf05/8107dafc-1183-49b2-9f08-b17e009ca837/image.jpg?t=1716889288&size=small",
            publishDate: "28/05/2024",
            author: "באדיבות המרואיין",
            audio: "/audio/2.mp3",
            type: "audio"
        },
        {
            url: "https://x.com/Now14Israel/status/1771643328544526744",
            title: "גיבורים בתחפושת: הליצנים הרפואיים שפועלים בבתי החולים כל שנה - מנסים להחזיר לפצועים את החיוך לפנים",
            publishDate: "23/03/2024",
            author: "ערוץ 14",
            image: "/other/img6.png",
            type: "video"
        },
        {
            url: "https://www.maariv.co.il/news/israel/Article-1085457",
            title: "\"בוכה בפנים\": ליצני בתי חולים משתפים באתגר המורכב שבזמן מלחמה\n",
            author: "דודי פטימר",
            publishDate: "21/03/2024",
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/899683",
            type: "article"
        },
        {
            url: "https://ejewishphilanthropy.com/since-oct-7-medical-clowns-have-brought-humor-to-survivors-evacuees-a-new-study-says-its-helping/",
            title: "Since Oct. 7, medical clowns have brought humor to survivors, evacuees; a new study says it’s helping",
            image: "https://ejewishphilanthropy.nyc3.digitaloceanspaces.com/wp-content/uploads/2024/02/22072958/zaza-800x520.jpg",
            author: "Judith Sudilovsky",
            publishDate: "22/02/2024",
            type: "article",
            english: true
        },
        {
            url: "https://1075.fm/item/154331/",
            image: "/other/img23.png",
            title: "לא רק מצחיק: ליצנות רפואית תורמת לאיכות השינה ולקיצור זמן אשפוזם של המטופלים",
            publishDate: "25/02/2024",
            type: "article",
            author: "מערכת רדיו חיפה"
        },
        {
            url: "https://haipo.co.il/item/503334",
            title: "ליצנים רפואיים במרפאות כללית מחוז חיפה וגליל מערבי",
            publishDate: "20/02/2024",
            image: "/other/img7.png",
            type: "article",
            author: "כללית"
        },
        {
            url: "https://102fm.co.il/tzafir/?Summyid=154230",
            title: "נמרוד איזנברג, מנהל הפיתוח המקצועי של רופאי חלום, עמותת הליצנים הרפואיים ובעצמו ליצן רפואי עם שם הבמה- ''מקס'' על איך הומור עוזר לנו לרפא את הנפש?",
            publishDate: "02/02/2024",
            image: "https://www1.nobexpartners.com/ODItem.aspx?id=65bcf861911bd9e9217b7db9.46434.i.jpg",
            author: "נמרוד איזנברג",
            audio: "/audio/3.mp3",
            type: "audio",
        },
        {
            url: "https://www.be106.net/55/83691",
            title: "בית החולים הרצפלד בגדרה ערך הפנינג חגיגי יחד עם ליצנים רפואיים למען המטופלים והפגת הבדידות של בני הגיל השלישי במרכז הגריאטרי",
            publishDate: "21/01/2024",
            image: "https://newzim.co.il/merkaz/wp-content/uploads/sites/2/2024/01/%D7%9C%D7%99%D7%A6%D7%A0%D7%99%D7%9D-%D7%A8%D7%A4%D7%90%D7%95%D7%99%D7%99%D7%9D.jpeg",
            type: "article",
            author: "מערכת האתר",
            note: "הכתבה בעמוד 2"
        },
        {
            url: "https://www.ynetnews.com/health_science/article/rkf6v00oer",
            title: "Israeli medical clowns bring joy to Taiwanese children",
            author: "Itamar Eichner",
            publishDate: "01/06/2024",
            english: true,
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/format=auto/picserver5/crop_images/2024/05/29/B1RAIUE4A/B1RAIUE4A_0_0_2048_1536_0_x-large.jpg",
            type: "article",
        },
        {
            url: "https://www.mivzaklive.co.il/archives/814517",
            title: "נקודת אור דיפלומטית: ליצנות הומניטרית ישראלית בטייואן",
            publishDate: "29/05/2024",
            image: "https://i0.wp.com/www.mivzaklive.co.il/wp-content/uploads/2024/05/WhatsApp-Image-2024-05-29-at-12.00.45.jpeg?resize=1024%2C768&ssl=1",
            type: "article",
            author: "יובל אביב",
        },
        {
            url: "https://www.ynet.co.il/health/article/syho2une0?utm_source=ynet.app.android&utm_medium=social&utm_campaign=whatsapp&utm_term=syho2une0&utm_content=Header",
            title: "ליצנים רפואיים מישראל באו לשמח ילדים בטייוואן אחרי רעידת אדמה",
            publishDate: "29/05/2024",
            author: "איתמר אייכנר",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/05/29/By4aL8NV0/By4aL8NV0_0_0_2048_1536_0_x-large.jpg",
            type: "article"
        },
        {
            url: "https://ntdtv.co.il/world/7926",
            title: "ליצנים רפואיים מישראל מגישים סיוע נפשי בטייוואן",
            publishDate: "01/06/2024",
            author: "NTD",
            image: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_1079/https://ntdtv.co.il/assets/uploads/2024/06/XXXXXXXXXXXXXXXXXX.jpg",
            type: "article"
        },
        {
            url: "https://newshaifakrayot.net/?p=325385",
            title: "מחקר חדש מהמרכז הרפואי כרמל קובע: ליצנים רפואיים משפרים את איכות השינה של ילדים המאושפזים בביה\"ח ותורמים לקיצור זמני אשפוזם",
            publishDate: "25/02/2024",
            author: "מערכת ניוז חיפה קריות",
            image: "https://newshaifakrayot.net/wp-content/uploads/2024/02/%D7%94%D7%9C%D7%99%D7%A6%D7%9F-%D7%90%D7%99%D7%AA%D7%99-%D7%A0%D7%97%D7%9E%D7%99%D7%90%D7%A1-%D7%A2%D7%9D-%D7%99%D7%9C%D7%93-%D7%91%D7%9E%D7%97%D7%9C%D7%A7%D7%AA-%D7%99%D7%9C%D7%93%D7%99%D7%9D-%D7%91%D7%9B%D7%A8%D7%9E%D7%9C-768x576.jpg",
            type: "article"
        },
        // {
        //     url: "https://www.rinunim.co.il/Article.php?CatID=&Page=&ArticleID=56882",
        //     title: "מחקר חדש מהמרכז הרפואי 'כרמל' מקבוצת 'כללית': ליצנים רפואיים משפרים את איכות השינה של ילדים המאושפזים בבית החולים.כתבה: רונית קיטאי",
        //     image: "https://www.rinunim.co.il/Pics/2024/20240220090240-s.jpg",
        //     publishDate: "20/02/2024",
        //     author: "רינונים",
        // },
        {
            url: "https://doctorsonly.co.il/2024/02/305803/",
            title: "מחקר ב\"כרמל\" הוכיח את תרומתם של ליצנים רפואיים לילדים באשפוז",
            publishDate: "20/02/2024",
            author: "מערכת דוקטורס אונלי",
            image: "https://cdn.doctorsonly.co.il/2024/02/WhatsApp-Image-2024-02-19-at-11.22.58-e1708409852501-992x558.jpeg",
        },
        {
            url: "https://apchi.co.il/?p=15448",
            title: "צחוק הוא התרופה: ליצנים רפואיים מקצרים אשפוזים ומשפרים שינה",
            image: "https://apchi.co.il/wp-content/uploads/2024/02/WhatsApp-Image-2024-02-18-at-08.48.08-683x1024.jpeg",
            publishDate: "18/02/2024",
            author: "שרהל'ה סנגאוי"
        },
        {
            url: "https://carmelist.co.il/item/85285",
            title: "ליצנים רפואיים משפיעים על איכות השינה של ילדים",
            publishDate: "19/02/2024",
            author: "מערכת כרמליסט",
            image: "https://carmelist.co.il/wp-content/uploads/2024/02/Screenshot-2024-02-19-085226-505x567.png"
        },
        {
            url: "https://www.maariv.co.il/news/health/Article-1076979",
            title: "מחקר ישראלי חדש חושף: כך ליצנים רפואיים משפרים את מצבם של ילדים",
            publishDate: "18/02/2024",
            author: "ד\"ר איתי גל",
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/894115"
        },
        {
            url: "https://apchi.co.il/?p=15337",
            title: "מחקר חדש מוכיח: כך משפיעים הליצנים הרפואיים על ההחלמה",
            publishDate: "29/01/2024",
            author: "יוסף יצחק מיכאלשוילי",
            image: "https://apchi.co.il/wp-content/uploads/2024/01/IMG-20240128-WA0022-1-768x576.jpg"
        },
        {
            url: "https://newzim.co.il/merkaz/?p=41413",
            title: "הפנינג של ליצנים רפואיים למשפחות המטופלים וצוות העובדים התקיים במרכז הרפואי גריאטרי שיקומי הרצפלד בגדרה",
            publishDate: "24/01/2024",
            author: "מערכת ניוזים",
            image: "https://newzim.co.il/merkaz/wp-content/uploads/sites/2/2024/01/%D7%9C%D7%99%D7%A6%D7%A0%D7%99%D7%9D-%D7%A8%D7%A4%D7%95%D7%90%D7%99%D7%99%D7%9D-%D7%91%D7%9E%D7%A8%D7%9B%D7%96-%D7%94%D7%92%D7%A8%D7%99%D7%90%D7%98%D7%A8%D7%99-%D7%92%D7%93%D7%A8%D7%94.jpeg"
        }
    ]
};


const LungsDoctors2024part1: Collection = {
    title: "האיגוד הישראלי לרפואת ריאות",
    client: LungsDoctors,
    items: [
        {
            url: "https://doctorsonly.co.il/2023/11/299379/",
            title: "קריאה להרחיב את תכנית השיקום לחולי COPD בסל התרופות",
            publishDate: "26/11/2023",
            image: "https://cdn.doctorsonly.co.il/2022/11/-%D7%A4%D7%A8%D7%99%D7%93%D7%9C%D7%A0%D7%93%D7%A8-1-e1700121444985-992x558.jpg",
            author: "מערכת דוקטורס אונלי"
        },
        {
            url: "https://www.e-med.co.il/specialties/family-medicine/2023/11/15/573490",
            title: "לרגל יום המודעות ל-COPD קורא יו\"ר האיגוד לרפואת ריאות להגדיל את התקציב לטיפול במחלה בסל הבריאות",
            publishDate: "15/11/2023",
            image: "/other/img5.png",
            author: "מערכת e-Med"
        },
        {
            url: "https://www.maariv.co.il/news/health/Article-1052617",
            title: "מעשנים יותר מאז פרוץ המלחמה? אתם נמצאים בסיכון לחלות במחלה הזו",
            publishDate: "16/11/2023",
            author: "מעריב אונליין",
            image: "https://www.maariv.co.il/HttpHandlers/ShowImage.ashx?id=391709&w=690&h=470"
        },
        {
            url: "https://www.news08.net/%D7%99%D7%9E%D7%99-%D7%9E%D7%9C%D7%97%D7%9E%D7%94-%D7%9E%D7%97%D7%9C%D7%95%D7%AA-%D7%A8%D7%99%D7%90%D7%94-%D7%9B%D7%A8%D7%95%D7%A0%D7%99%D7%95%D7%AA-%D7%9E%D7%97%D7%9E%D7%99%D7%A8%D7%95%D7%AA-%D7%AA/",
            image: "/other/img4.png",
            title: "ימי מלחמה: מחלות ריאה כרוניות מחמירות תחת תנאי סטרס",
            author: "שרון מאירוביץ",
            publishDate: "04/12/2023",
        },
        {
            url: "https://www.0404.co.il/?p=939252",
            title: "תופעה מתרחבת: המחלות שמחמירות בתקופת מלחמה",
            publishDate: "05/12/2023",
            author: "חדשות 0404",
            image: "/other/img9.png"
        },
        {
            url: "https://m.news1.co.il/Archive/001-D-478542-00.html?AfterBan=1",
            title: "תופעה מתרחבת: מחלות ריאה כרוניות מחמירות בתקופת מלחמה",
            publishDate: "05/12/2023",
            image: "/other/img3.png",
            author: "מירב ארד"
        },
        {
            url: "https://www.bhol.co.il/news/1624794",
            title: "תופעה מתרחבת: מחלות ריאה כרוניות מחמירות בתקופת מלחמה",
            publishDate: "05/12/2023",
            author: "ישראל לפקוביץ",
            image: "https://storage.bhol.co.il/articles/656579_tumb_800X480.jpg"
        },
        {
            url: "https://apchi.co.il/?p=14887",
            title: "לא כיף: איך מתמודדים עם מחלות ריאה כרוניות בימי מלחמה",
            author: "יוסף יצחק מיכאלשוילי",
            publishDate: "04/12/2023",
            image: "https://apchi.co.il/wp-content/uploads/2023/12/IMG-20231116-WA0006.jpg"
        },
        {
            url: "https://apchi.co.il/?p=14754",
            title: "ידעתם על זה? היום הוא יום המודעות בעולם ל- COPD מחלת ריאות חסימתית כרונית",
            image: "https://apchi.co.il/wp-content/uploads/2023/11/IMG-20231116-WA0006-768x748.jpg",
            author: "יוסף יצחק מיכאלשוילי",
            publishDate: "16/11/2023"
        },
        {
            url: "https://www.maariv.co.il/news/health/Article-1057448",
            title: "המחלה המיסתורית מסין: באיזה חיידק מדובר והאם בעולם צריכים לחשוש?",
            publishDate: "05/12/2023",
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/557840",
            author: "סתיו נמר"
        },
        {
            url: "https://www.mivzaklive.co.il/archives/751108",
            title: "תופעה מתרחבת: מחלות ריאה כרוניות מחמירות בתקופת מלחמה",
            publishDate: "05/12/2023",
            image: "https://i0.wp.com/www.mivzaklive.co.il/wp-content/uploads/2023/12/pexels-mart-production-7088530.jpg?w=800&ssl=1",
            author: "איתמר רובינשטיין"
        },
        {
            url: "https://tip.co.il/%D7%94%D7%AA%D7%9E%D7%95%D7%93%D7%93%D7%95%D7%AA-%D7%A2%D7%9D-%D7%9E%D7%97%D7%9C%D7%95%D7%AA-%D7%A8%D7%99%D7%90%D7%94-%D7%9B%D7%A8%D7%95%D7%A0%D7%99%D7%95%D7%AA-%D7%91%D7%99%D7%9E%D7%99-%D7%94/",
            title: "התמודדות עם מחלות ריאה כרוניות בימי המלחמה",
            publishDate: "06/12/2023",
            image: "https://tip.co.il/wp-content/uploads/2023/12/%D7%A4%D7%A8%D7%95%D7%A4-%D7%A6%D7%91%D7%99-%D7%A4%D7%A8%D7%99%D7%93%D7%9C%D7%A0%D7%93%D7%A8-%D7%99%D7%95%D7%A8-%D7%90%D7%99%D7%92%D7%95%D7%93-%D7%A8%D7%95%D7%A4%D7%90%D7%99-%D7%94%D7%A8%D7%99%D7%90%D7%95%D7%AA-%D7%91%D7%99%D7%A9%D7%A8%D7%90%D7%9C-1-768x748.jpg",
            author: "מערכת טיפ"
        },
        {
            url: "https://www.ynet.co.il/health/article/yokra13716936?utm_source=ynet.app.android&utm_medium=social&utm_campaign=whatsapp&utm_term=yokra13716936&utm_content=Header",
            title: "החולים חוזרים למסדרון: עלייה במחלות בדרכי הנשימה",
            publishDate: "14/12/2023",
            author: "אדיר ינקו",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2023/12/13/yk13716882/yk13716882_15_56_814_610_0_x-large.jpg"
        },
        {
            url: "https://www.haaretz.co.il/health/2024-02-14/ty-article/.premium/0000018d-a29f-d42f-adcd-ebff83310000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "למה האוצר הקפיא את מחיר הסיגריות האלקטרוניות?",
            publishDate: "14/02/2024",
            author: "אדיר רוקח",
            image: "https://img.haarets.co.il/bs/0000018d-a2b2-d400-a7cd-ffb7a2200001/a2/02/51e9e19e4660a03a0ff74bb25027/58228.jpg?&width=1500&height=872&cmsprod"
        },
        {
            url: "https://www.haaretz.co.il/health/2024-02-26/ty-article/.premium/0000018d-e548-d596-a9ef-e7fbf3130000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "\"כולם חולים עכשיו\" - האם מחלות החורף באמת הכו חזק יותר השנה?",
            author: "עידו אפרתי",
            publishDate: "26/02/2024",
            image: "https://img.haarets.co.il/bs/0000018d-e556-d47b-a1af-ff76d8130001/e6/52/7d7c50b345b492ecb2db33e4ddf8/689775.jpg?&width=1500&height=872&cmsprod"
        },
        {
            url: "https://doctorsonly.co.il/2024/04/314429/",
            title: "קופות החולים מתוגמלות על שיקום ריאות, אך לא מציעות אותו לחולים",
            publishDate: "17/04/2024",
            author: "מערכת דוקטורס אונלי",
            image: "https://cdn.doctorsonly.co.il/2024/04/shutterstock_794723350-992x558.jpg"
        },
        {
            url: "https://www.bhol.co.il/news/1661421",
            title: "מחלת האסתמה: \"היא עלולה להופיע בכל גיל\"",
            publishDate: "06/05/2024",
            image: "https://storage.bhol.co.il/articles/656579_tumb_800X480.jpg",
            author: "קובי ברקת"
        },
        {
            url: "https://apchi.co.il/?p=15941",
            title: "יום המודעות העולמי לאסתמה: לא אויב, אלא אתגר – בואו נתמודד יחד",
            publishDate: "06/05/2024",
            author: "שרהל'ה סנגאוי",
            image: "https://apchi.co.il/wp-content/uploads/2024/05/37882ecc-2b4c-49d1-949f-b958ceb4b6ee-768x768.jpg"
        },
        {
            url: "https://doctorsonly.co.il/2024/06/319032/",
            title: "המשמעות של גישה פרו-אקטיבית בניהול מחלות נשימה כרוניות",
            publishDate: "23/06/2024",
            image: "https://cdn.doctorsonly.co.il/2021/10/shutterstock_1746786008.jpg",
            author: "מערכת דוקטורס אונלי"
        },
        {
            url: "https://www.israelhayom.co.il/health/article/14598400",
            title: "חשופים לסכנה: זינוק בעישון בקרב בני נוער, כל ילד שלישי בישראל נחשף לעישון פסיבי",
            author: "מיטל יסעור בית-אור",
            publishDate: "12/09/2023",
            image: "https://www.israelhayom.co.il/wp-content/uploads/2022/04/04/1546205804912_b-960x640.jpg"
        },
        {
            url: "https://doctorsonly.co.il/2023/09/295246/",
            title: "\"ישראל נכשלת במאבק בעישון\"",
            publishDate: "12/09/2023",
            image: "https://cdn.doctorsonly.co.il/2022/08/shutterstock_527589082-992x558.jpg",
            author: "מערכת דוקטורס אונלי"
        },
        {
            url: "https://www.hidabroot.org/article/1186011",
            title: "משרד הבריאות בדו\"ח מיוחד: עלייה מדאיגה במספר המעשנים בגילאי 11-12",
            publishDate: "12/09/2023",
            image: "https://storage.hidabroot.org/articles_new/342644_tumb_750Xauto.jpg",
            author: "שלומי דיאז"
        },
        {
            url: "https://www.mako.co.il/news-med12/lung_ambition/Article-c02943254ac3a81027.htm",
            title: "\"עבור ילדים ובני נוער סיגריה אלקטרונית זו עבודה בעיניים\"",
            publishDate: "06/09/2023",
            image: "https://img.mako.co.il/2019/09/25/shutterstock_233695804_x5.jpg",
            author: "אסנת עופר"
        },
        {
            url: "https://www.maariv.co.il/news/health/Article-1037913",
            title: "רופאים תוקפים את חזרת פינות העישון לנתב\"ג: \"יש לגנוז את הרעיון ויפה שעה אחת קודם\"",
            publishDate: "13/09/2023",
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/488507",
            author: "סתיו נמר"
        },
        {
            url: "https://www.bhol.co.il/news/1595412",
            title: "גורמי רפואה במתקפה על הצעד בנתב\"ג: \"נזק בלתי הפיך\"",
            publishDate: "13/09/2023",
            author: "מאיר גלבוע",
            image: "https://storage.bhol.co.il/articles/246441_tumb_800X480.jpg"
        },
        {
            url: "https://www.ynet.co.il/health/article/yokra13618223?utm_source=ynet.app.android&utm_medium=social&utm_campaign=general_share&utm_term=yokra13618223&utm_content=Header",
            title: "שבועיים בלי מצנתר מוח בדרום: כך הופקרו תושבי הפריפריה | מעקב",
            publishDate: "05/10/2023",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2023/10/05/B1W4RRnjxT/B1W4RRnjxT_0_0_1000_563_0_x-large.jpg",
            author: "אדיר ינקו"
        },
        {
            url: "https://doctorsonly.co.il/2023/10/296346/",
            title: "מחסור חמור במצנתרי מוח בפריפריה: \"חלון ההזדמנויות לטיפול נסגר\"",
            image: "https://cdn.doctorsonly.co.il/2017/02/%D7%97%D7%93%D7%A8-%D7%90%D7%A0%D7%92%D7%99%D7%95-992x558.jpg",
            publishDate: "05/10/2023",
            author: "מערכת דוקטורס אונלי"
        },
    ]
};


const LungsDoctors2024part2: Collection = {
    title: "האיגוד הישראלי לרפואת ריאות",
    client: LungsDoctors,
    items: [
        {
            url: "https://www.jpost.com/israel-hamas-war/article-822203",
            title: "'Right to breathe freely': Israeli medical professionals think of hostages on World Lung Day\n",
            publishDate: "28/09/2024",
            author: "Jerusalem Post Staff",
            image: "https://images.jpost.com/image/upload/q_auto/c_fill,g_faces:center,h_537,w_822/620897",
            english: true
        },
        {
            //הרופאים הישראלים לעמיתיהם בעולם: "אסור לשכוח את החטופים, אין להם אוויר"
            // חברי האיגוד הישראלי לרפואת ריאות בהסתדרות הרפואית, שיגרו מכתב לעמיתיהם בעולם, בו הפצירו בהם "לחשוב על החטופים בכל יום - ולעזור להבטיח את זכותם הבסיסית לנשום"
            // שלו שינברג
            // כ"ג אלול התשפ"ד  26.09.24 | 09:07
            url: "https://www.bhol.co.il/news/1674980",
            title: "הרופאים הישראלים לעמיתיהם בעולם: \"אסור לשכוח את החטופים, אין להם אוויר\"",
            publishDate: "26/09/2024",
            author: "שלו שינברג",
            image: "https://storage.bhol.co.il/media/lt/1653832/gallery/710066_tumb_800X480.jpg"
        },
        {
            url: "https://www.ynet.co.il/health/article/hk80skwra",
            title: "רופאים מישראל בפנייה לעמיתיהם בעולם: \"אסור לשכוח את החטופים, אין להם אוויר\"",
            publishDate: "26/09/2024",
            author: "רון קריסי",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/09/21/rJylm53a0/rJylm53a0_0_146_3000_1688_0_x-large.jpg"
        },
        {
            url: "https://doctorsonly.co.il/2024/09/327542/",
            title: "\"להבטיח את זכותם הבסיסית של החטופים לנשום בחופשיות\"",
            publishDate: "25/09/2024",
            author: "מערכת דוקטורס אונלי",
            image: "https://cdn.doctorsonly.co.il/2024/09/F240214MA015_pr-640x360.jpg"
        },
        {
            url: "https://www.mivzaklive.co.il/archives/853207",
            title: "חברי האיגוד הישראלי לרפואת ריאות מזכירים לעולם את החטופים בעזה",
            publishDate: "24/09/2024",
            author: "אריאל מונק",
            image: "https://i0.wp.com/www.mivzaklive.co.il/wp-content/uploads/2023/05/doctor-ge6cb519f3_1280.jpg?w=800&ssl=1"
        },
        {
            url: "https://doctorsonly.co.il/2024/08/324064/",
            title: "רופאים עם כוח-על",
            author: "טל דניאל-חבקוק",
            publishDate: "08/08/2024",
            image: "https://cdn.doctorsonly.co.il/2024/08/111-992x558.jpg"
        },
        {
            url: "https://medpage.co.il/%D7%9E%D7%97%D7%9C%D7%95%D7%AA-%D7%A8%D7%99%D7%90%D7%94-%D7%AA%D7%A2%D7%A1%D7%95%D7%A7%D7%AA%D7%99%D7%95%D7%AA-%D7%94%D7%90%D7%AA%D7%92%D7%A8-%D7%94%D7%A0%D7%A1%D7%AA%D7%A8-%D7%91%D7%A1%D7%91%D7%99/",
            title: "מחלות ריאה תעסוקתיות: האתגר הנסתר בסביבת העבודה",
            author: "ד\"ר עינת פיירמן-קליין",
            publishDate: "28/08/2024",
            image: "https://medpage.co.il/wp-content/uploads/2024/08/shutterstock_1870491376.jpg"
        },
        {
            url: "https://www.news08.net/%D7%96%D7%94%D7%95-%D7%99%D7%95%D7%9D-%D7%98%D7%A8%D7%90%D7%92%D7%99-%D7%9C%D7%97%D7%98%D7%95%D7%A4%D7%99%D7%9D-%D7%9C%D7%90-%D7%A0%D7%A9%D7%90%D7%A8-%D7%90%D7%95%D7%95%D7%99%D7%A8-%D7%91%D7%A8%D7%99/",
            title: "זהו יום טראגי, לחטופים לא נשאר אוויר בריאות ונגמר להם הזמן",
            author: "ליזה ללוצאשווילי",
            publishDate: "24/09/2024",
            image: "/other/img1.png"
        },
        {
            url: "https://www.bhol.co.il/news/1666787",
            title: "למעלה מ-400,000 חולים בארה\"ב בברונכיאקטזיס, בארץ - עשרות אלפי חולים",
            author: "אלי קליין",
            publishDate: "01/07/2024",
            image: "https://storage.bhol.co.il/media/lt/1652664/gallery/683667_tumb_800X480.jpg"
        },
        {
            url: "https://www.timesofisrael.com/on-world-lung-day-israeli-groups-highlight-plight-of-hostages-who-lack-even-air/",
            title: "On World Lung Day, Israeli groups highlight plight of hostages who ‘lack even air’",
            author: "Diana Bletter",
            publishDate: "26/09/2024",
            image: "https://static.timesofisrael.com/www/uploads/2024/01/WhatsApp_Image_2024-01-20_at_17.43.16-640x400.jpeg"
        },
        {
            url: "https://www.ynet.co.il/health/article/rkbp6nj9a",
            title: "האוויר שאנחנו נושמים בעבודה עלול לסכן. מה עושים?",
            author: "ד\"ר עינת פיירמן קליין",
            publishDate: "26/08/2024",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/07/29/HykRd4BFR/HykRd4BFR_0_0_1000_500_0_x-large.jpg"
        },
        {
            url: "https://aurora-israel.co.il/en/On-World-Lung-Day%2C-pulmonologists-pray-for-the-hostages-in-Gaza/",
            title: "On World Lung Day, pulmonologists pray for Gaza hostages",
            publishDate: "25/09/2024",
            author: "Aurora News",
            image: "/other/img8.png",
            english: true
        },
        {
            url: "https://x.com/kann_news/status/1780305876558942273",
            title: "ההסתדרות הרפואית שלחה למנכ\"לי קופות החולים מכתב חריף: אתם חוסכים על חולים שזקוקים לשיקום ריאות",
            author: "כאן חדשות",
            publishDate: "16/04/2024",
            image: "/other/img21.png"
        },
        {
            url: "https://medpage.co.il/%D7%9E%D7%94%D7%99-%D7%91%D7%A8%D7%95%D7%A0%D7%9B%D7%95%D7%A1%D7%A7%D7%95%D7%A4%D7%99%D7%94-%D7%95%D7%9E%D7%94-%D7%97%D7%A9%D7%99%D7%91%D7%95%D7%AA%D7%94-%D7%91%D7%90%D7%91%D7%97%D7%95%D7%9F-%D7%95/",
            title: "מהי ברונכוסקופיה ומהי חשיבותה באבחון וטיפול במערכת הנשימה?",
            author: "ד\"ר יבגני גרשמן",
            publishDate: "21/11/2024",
            image: "https://medpage.co.il/wp-content/uploads/2024/11/shutterstock_1807589032.jpg"
        },
        {
            url: "https://doctorsonly.co.il/2024/11/330894/",
            title: "נייר עמדה חדש: המלצות לשחרור לאחר אשפוז בשל התלקחות COPD",
            image: "https://cdn.doctorsonly.co.il/2020/11/shutterstock_470436206.jpg",
            author: "מערכת דוקטורס אונלי",
            publishDate: "20/11/2024"
        },
        {
            url: "https://doctorsonly.co.il/2023/11/299379/",
            title: "קריאה להרחיב את תכנית השיקום לחולי COPD בסל התרופות",
            image: "https://cdn.doctorsonly.co.il/2022/11/-%D7%A4%D7%A8%D7%99%D7%93%D7%9C%D7%A0%D7%93%D7%A8-1-e1700121444985-992x558.jpg",
            author: "מערכת דוקטורס אונלי",
            publishDate: "16/11/2024"
        },
        {
            url: "https://apchi.co.il/?p=17164",
            title: "COPD: סיבת המוות השלישית בעולם – ואיך מונעים אותה?",
            image: "https://apchi.co.il/wp-content/uploads/2024/11/%D7%AA%D7%9E%D7%95%D7%A0%D7%94-%D7%A9%D7%9C-WhatsApp%E2%80%8F-2024-11-20-%D7%91%D7%A9%D7%A2%D7%94-10.02.00_e701d4db.jpg",
            author: "שרהל'ה סנגאוי",
            publishDate: "20/11/2024"
        },
        {
            url: "",
            title: "איגוד רופאי הריאות של ההסתדרות הרפואית מאשים את ארבעת קופות החולים",
            image: "/other/img24.png",
            publishDate: "16/11/2024",
            audio: "/audio/1.mp3",
            author: "אריה גולן",
            type: "audio"
        },
    ]
};


const LungsDoctors2024merged: Collection = {
    title: "האיגוד הישראלי לרפואת ריאות",
    client: LungsDoctors,
    items: [...LungsDoctors2024part1.items, ...LungsDoctors2024part2.items]
};


const GrainImporters2024: Collection = {
    title: "איגוד יבואני התבואות",
    client: GrainImportersAssociation,
    items: [
        {
            url: "https://www.themarker.com/dynamo/2024-01-08/ty-article/0000018c-e88b-db55-a39e-ff9fe4370000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            image: "https://img.haarets.co.il/bs/0000018c-e92e-db55-a39e-ffbef26c0000/73/ca/19127b8c407aa766237c752da98b/62198.jpg?precrop=2324,1351,x0,y0&width=1500&height=872&cmsprod",
            title: "דו\"ח מבקר המדינה בנמל אשדוד, העבודה נעצרת כשהעובדים מגיעים לבונוס היומי",
            publishDate: "08/01/2024",
            author: "עידן בנימין",
            modal: [
                `נזק של כ-5.1 מיליארד שקל ליצוא וכ-8.7 מיליארד שקל ליבוא נגרמו כתוצאה מהפקקים בנמלים בישראל בשנת 2021 - כך עולה מנתוני משרד האוצר אליהם מתייחס מבקר המדינה בדו"ח שפורסם היום (ב') על פעילות נמלי ישראל. להערכת המבקר, הפקקים הביאו לתוספת של 0.1% לאינפלציה, שעמדה על 2.8% ב-2021. כלומר, מנתוני משרד האוצר עולה שהאינפלציה הייתה יכולה לעמוד באותה שנה על 2.7%.

בדו"ח מתייחס המבקר בעיקר לכישלון התפעולי המתמשך בנמל אשדוד, אותו הוא מדרג במקום ה-13 מתוך 14 נמלים בני השוואה בעולם.`,

                {data: "https://img.haarets.co.il/bs/0000018c-e924-d249-a1ce-ef65fad90000/c3/80/457055f043febeaf366159a4d27e/591689-2.jpg?&width=1500&height=1004&cmsprod", type: "image"},
                
                `המבקר מתאר בדו"ח, בלשונו העדינה, כיצד בגלל כשל ניהולי של משרד התחבורה, רשות הספנות, חברת נמלי ישראל והנהלת נמל אשדוד, השירות בנמל מידרדר והולך; איך מערך התמריצים בנמל מביא עובדים להפסיק לעבוד באמצע המשמרת או לא לעבוד לילות; ולכך שהנמל ממצה רק חלק מעובדי התפעול שלו מסיבות שונות - מה שגורם למחסור בעובדים ולתור ארוך בכניסה לנמל. התור הגיע בשיאו ל-80 אוניות במאי 2021, ולמספר דומה במאי 2022.

לפי מסמך של משרד האוצר שפורסם ב-2021 בעקבות הפקק בנמלים ומוזכר בדו"ח, "כל יום עיכוב עלה למפעילי אונייה כ-20 אלף דולר, וחלק מעלות זו גולגלה ליבואנים וליצואנים ומשם לצרכנים. חלק מחברות הספנות ביטלו את הגעתן לנמלים בארץ ופרקו את הסחורה בארצות שכנות, ואחרות הטילו עלויות נוספות על היבואנים והיצואנים כתוצאה מההמתנה הממושכת. על כן, מחירי התובלה הימית עלו ובמקרים מסוימים אף הוכפלו. נוסף על כך, אלפי מכולות ריקות המתינו להעמסה בנמלים ויצרו מחסור משמעותי, שייקר גם הוא את עלויות ההובלה, ואיחורים בהגעה של חומרי גלם עיכבו את שרשרת הייצור ופגעו גם ביצוא".`,

                {data: "העובדים בנמל מסיימים לעבוד לאחר שזכו בבונוס למשמרת", type: "subtitle"},
                
                `הביקורת על עבודת הנמלים בוצעה בין אוגוסט 2022 לאפריל 2023. באותה תקופה היו בישראל שני נמלים ממשלתיים - נמל אשדוד ונמל חיפה - וארבעה נמלים פרטיים: נמל המפרץ (בחיפה), נמל מספנות ישראל (בחיפה), נמל הדרום (באשדוד) ונמל אילת. מאז הביקורת נמכר נמל חיפה לקבוצת אדאני־גדות, כך שהנמל היחיד שנשאר ממשלתי הוא נמל אשדוד, ואליו מופנה עיקר הביקורת. המבקר מתאר בדו"ח כיצד הנמל מבזבז את הנכס החשוב ביותר שלו – קו המים.

הנכס הכי חשוב בנמל הוא קו המים (נקודת המפגש בין הים ליבשה), שהוא משאב מוגבל. ככל שעוברת דרכו יותר סחורה בזמן קצר יותר, הנמל מרוויח יותר כסף.`,

                {data: "https://img.haarets.co.il/bs/0000018c-e931-d249-a1ce-ef756d500001/f1/57/37f9305e49adb0cde1c9ecb3c414/balayla-desk.png?&width=1500&height=817&cmsprod", type: "image"},
                
                `כדי לתמרץ את עובדי הנמל לעבוד במהירות, בנמל אשדוד נותנים בונוס (פרמיה) לעובדים שמגיעים לתפוקה מסוימת במשמרת. הבעיה בתמריץ הזה, מתברר, היא שלאחר שהגיעו למכסה שמזכה אותם במענק שגובהו קבוע אך לא פורסם בדו"ח, אין לעובדים סיבה להמשיך לעבוד. עובדי משרד המבקר מצאו ביומן האירועים, לצד הפסקות עבודה עקב ערפל או תקלות בציוד, גם את הרישום "היד (הצוות; ע"ב) הגיעה למלוא כמות הפרמיה - היד נשארת בהמתנה עד גמר המשמרת". העובדים פשוט הפסיקו לעבוד, מאחר שהמשך העבודה לא היה מניב להם הכנסה נוספת (למעט על שעות העבודה).

בנמל אומרים כי חברת נמל אשדוד ניסתה להגיע להסדר חדש עם העובדים בנוגע לפרמיות, אך ללא הצלחה. עוד ציינה החברה כי "התופעה קיימת במטענים מסוימים (מתכות וברזל), ששיעורם כ-3% מפעילות הנמל".

חוץ מלעצור משמרות באמצע בגלל הבונוס, יש עוד דרכים לבזבז את קו המים של הנמל. אחת מהן היא לעבוד רק חלק מהיממה. המבקר מצביע על כך שבנמל אשדוד ונמל חיפה (שהופרט מאז) עובדים רק בחלק משעות היממה. נמל אשדוד הוא היחיד שלא עובד בשבתות וחגים, ובימי חול רגילים עובדים בו בעיקר בבוקר. לפי נתוני חברת נמלי ישראל, בתחום המטען הכללי (מטען שלא משונע במכולות, כמו גלילי מתכת) והחופנים (מטען בתפזורת), רוב העובדים מוקצים למשמרת הבוקר - ואילו במשמרת הצהריים והלילה מספר העובדים המשובצים פוחת והולך. לפי הדו"ח, "הירידה כמעט לא מורגשת בתחום המכולות, אך בתחומי המטען הכללי וצובר החופנים, הירידה היא בשיעורים ניכרים - מהקצאה של 56% ו-52% במשמרת א' בנמל אשדוד ובנמל חיפה, בהתאמה, ל-9% ו-8% במשמרת ג' (משמרת לילה)". במילים אחרות, במשך שליש מהיממה רציף הנמל שומם.

בתשובת חברת נמל אשדוד צוין כי גם המצב הזה נובע מהסכמי העבודה, וגם ממחסור במשאיות שיגיעו בשעות הלילה, ומכך שמחסנים עורפיים גם הם לא עובדים בשעות הלילה. לטענת הנמל, "ניסיון שנעשה לתגמל יבואנים ויצואנים ששילחו מכולות בלילה (פרויקט לילה טוב) לא צלח עקב אי־היענות מצד נהגי המשאיות ומגבלות שעות נהיגה".

אלו רק שתי דוגמאות שמציין דו"ח מבקר המדינה בנוגע לחוסר היעילות של הנמל הממשלתי האחרון. המבקר כותב כי "יש ירידה בתפוקה לשעת עבודה בכל סוגי המטענים שצוינו, ובעיקר בצובר החופנים. ב-2022 חל שיפור בזמני ההמתנה והשהייה (של האוניות בנמל אשדוד; ע"ב), אך הם עדיין גבוהים בעשרות אחוזים מרמתם ב-2017. בתפוקה לשעת עבודה בנמל אשדוד, בעיקר במטען כללי וצובר חופנים, חלה ירידה ביחס ל-2017.

"בעיית היעילות של נמל אשדוד מתבטאת גם בהיענות להקצאת ידיים (עובדי משמרת), שירדה בעשרות אחוזים לעומת 2017. כן נמצא כי מסיבות שונות, 13.8%–22.0% מעובדי התפעול, שהיו יכולים להקטין את התור התפעולי, לא שובצו לעבודות פריקה וטעינה. בנמל אשדוד אף נמצא גידול בשנים 2018–2022 באי־זמינות של ציוד מתמחה, הכולל מנופים, מדלים, משפכים וציוד נוסף לניטול. גידול זה נגרם בעיקר מטיפול בתקלות שבר, וכן מביצוע תחזוקה מונעת בהיקף נמוך מאוד על ידי חברת נמל אשדוד. כל אלה מצביעים על כך שתור האוניות שהמתינו מחוץ לנמל אשדוד הושפע לא רק ממשבר הקורונה, אלא גם מבעיות תפעוליות מבניות, הכוללות בין השאר יעילות נמוכה של ניטול מטענים ואי־זמינות של ציוד מתמחה בנמל אשדוד, שהן בין הגורמים שפגעו ביעילות עבודת ניטול המטענים".

כדי להבין איפה עומד הנמל ביחס לעולם, בנו במשרד המבקר יחד עם חברת S&P מודל השוואה בין 14 נמלים שדומים לנמל אשדוד בגובה שיקוע המים, גודל האוניות שיכולות להיכנס לנמל וכיו"ב. המדד בודק את זמן ההמתנה של אוניית מכולות ממוצעת, וממקם את נמל אשדוד במקום ה-13 מתוך 14 נמלים. נמל חיפה דורג במקום השישי.`,

                {data: "https://img.haarets.co.il/bs/0000018c-e936-d765-ab9d-f97f3f340000/2d/cc/1bfc07de4f3c8de5b2fc0c7c5d30/74021.jpg?&width=1500&height=694&cmsprod", type: "image"},

                {data: "מי אחראי שההשקעה לא תרד לטימיון?", type: "subtitle"},
                
                `מסוע הגרעינים בנמל אשדוד הוקם כדי לגשר על מרחק של כ-2.2 ק"מ בין קו המים לממגורה (מחסן תבואה זמני). עד השנה שונעו הגרעינים על גבי מאות משאיות מדי יום בין הנמל לממגורה. הממגורה שהוקמה לבסוף בתקציב של 237 מיליון שקל, נמסרה על ידי הקבלן בדצמבר 2022 לאחר תקופת הרצה ומחלוקת משפטית עם הקבלן. המסוע והמדלים ששואבים את הגרעינים מהאוניה אל המסוע, יכולים לשנע עד 1,800 טונה בשעה. למרות זאת, בדיקת המבקר העלתה כי הקיבולת המרבית של המסוע היא 600 טונה בשעה. המבקר כותב כי "לפי התחזית, המסוע יעבור לתפוקה מלאה במרץ 2023".

של מי התחזית הזאת בדיוק? ומי אחראי על כך שההשקעה לא תרד לטימיון? המבקר לא מציין. נכון להיום, בינואר 2024, המסוע רחוק מאד ממימוש הפוטנציאל שלו. משרד המבקר המליץ לחברת נמל אשדוד לפעול לכך, שהמסוע יפעל בתפוקה מלאה, "באופן שישרת באופן מיטבי את הביקוש לגרעינים בדרום הארץ ובמרכזה".

מנמל אשדוד נמסר בתגובה: "ההשוואה לנמלים האחרים אינה עומדת בכללי השוואה אובייקטיביים. מדובר בחתך של 8% מהמטענים, כאשר מטענים אסטרטגיים, כגון מכולות ורכבים, לא המתינו כלל ואלה הם 85% מפעילות הנמל. זאת, כאשר במדינות המערב מטענים אלה סבלו מהמתנות של עד חודש.

"בדירוג הנמל במגזין Dynaliners ב-2023 (המתייחס ל-2020 ו-2021, נמל אשדוד דורג במקום 106 ו-109 בהתאמה. הדירוג בחר 130 נמלים מובילים מתוך אלפי נמלים וטרמינלים באיגוד הטרמינלים העולמי - IAPH. איגוד זה הגדיר במבוא שלו ל-2021 את משבר הקורונה כגדול ביותר מאז מלחמת העולם השנייה"

"נכון ל-2023 בואך 2024 הנמל מקבל את סך כל מטעניו ללא המתנות כלל. חשוב לציין שהדו"ח שמתייחס לנתוני 2022-2021 התקבל אתמול בלילה והחברה תלמד אותו ותמנה צוות לשיפור הביצועים. חברת נמל אשדוד עוסקת בשיפור מתמיד והנתונים של 2023 מוכיחים זאת".`
            ]
        },

        {
            url: "https://www.themarker.com/dynamo/2024-02-12/ty-article/.premium/0000018d-9cd3-df62-a3fd-9edf629b0000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "מתקן פריקת תבואה בנמל אשדוד הושבת - ומסוע שהושקעו בו 250 מיליון שקל עומד שומם",
            image: "https://img.haarets.co.il/bs/0000018d-9cd8-db74-a1ef-dfdaed1b0000/f8/8e/019e5bad400a9a0679b6cdbb374a/74021.jpg?&width=1500&height=872&cmsprod",
            publishDate: "12/02/2024",
            author: "עידן בנימין",
            modal: [
                `מסוע הגרעינים באשדוד ציין בחודש שעבר שנה לחניכתו. המסוע, שהושקעו בו כ-250 מיליון שקל, נועד לייעל את הליך הפריקה של הגרעינים בנמל לממגורה (מתקן אחסון ייעודי) שמרוחקת כ-2 ק"מ משם.

גרעינים הם שם כללי לסוגי מזון שונים: החל ממזון לתרגולות ועד לחיטה לאפיית לחם. את ההשקעה בפרויקט השאפתני מימנו משלמי המסים דרך שתי חברות הממשלתיות: חברת נמלי ישראל (חנ"י) ונמל אשדוד. המסוע נועד להחליף צי משאיות שנסע בין קו המים לממגורה הלוך ושוב. שנה עברה, רבע מיליארד שקל הושקעו - אך המשאיות חזרו למסלול.`,

                {data: "https://img.haarets.co.il/bs/0000018d-9cea-d92c-a9ed-fffbb4230000/10/bb/25acfa7d417db8b4475c5ea2d0d2/45945.jpg?&width=1500&height=844&cmsprod", type: "image"},
                
                `מדוע המסוע שומם? פריקת התבואה תלויה במתקן גדול (מַדְלֶה), אך שלושה מתקנים כאלה, שנכנסו לשימוש לפני שנה - והיו אמורים להזין את המסוע בקצב מכובד של עד 1,800 טונה בשעה ולפרוק אוניית גרעינים בתוך יממה - מושבתים.

המדלה הגדול, שיכול לשאוב עד 1,200 טונה גרעינים בשעה, עדיין לא הושמש. מדלה נוסף, בהספק של 600 טונה, רוסק בספטמבר בתאונת עבודה לאחר שמפעילו התנגש בבטן אוניית גרעינים. ביום חמישי האחרון שברו עובדי הנמל את המדלה השלישי, שהיה אמור לשמש כגיבוי. מאחר ששלושת המדלים מושבתים, אין אפשרות להעמיס את המסוע, והפריקה בנמל, כהיא מתרחשת, נעשית באמצעות כפות שמעבירות חופנים של גרעינים לתוך משאיות שנוסעות לממגורה.`,

                {data: "כרוניקה של כישלון ידוע מראש", type: "subtitle"},
                
                `מאז נחנך מסוע הגרעינים בינואר 2023 הוא מספק שיעור מזערי מיכולתו המרבית. אף שהוא יכול לשנע עד 1,800 טונה של גרעינים בשעה, הוא משנע רק 150 טונה בשעה בממוצע - כ-8% מהיכולת המקסימלית שלו. המשמעות היא שאוניות מחכות זמן רב בתור לנמל, פריקת הסחורה שלהן אטית והיבואנים נקנסים על ימי שימוש נוספים באונייה. העלות כמובן מגולגלת בסופו של יום לצרכנים.

כדי להפעיל את המסוע בתפוקה גבוהה נדרש שימוש במדלה גדול בהספק של 1,200 טונה (במקביל למדלה נוסף). אבל כדי שאפשר יהיה להפעיל אותו, עובדי הנמל נדרשים לעבור הסמכה של כמה ימים על ידי היצרן. מסיבה לא ברורה, ואף שעברה שנה מאז נחנך המסוע, העובדים עדיין לא הוסמכו לכך.

כך נותר לנמל לפרוק עם שני מדלים לפריקת גרעינים. לאחר שבספטמבר עובדי הנמל שברו מדלה, נותר מדלה בודד שאחראי על פריקת כל הגרעינים שנכנסים לדרום הארץ, וכעת גם הוא מושבת.`,

                {data: "https://img.haarets.co.il/bs/0000018d-9d0a-d92c-a9ed-fffb34e80001/fa/5e/e0dc8d024b29b76fdc080a7bd1a6/ashdod-desk.png?&width=1500&height=548&cmsprod", type: "image"},
                
                `האחריות לא נופלת רק על נמל אשדוד, אלא גם על בכירי משרד התחבורה שכשלו בתפקידם: שרת התחבורה מירי רגב, מנכ"ל משרד התחבורה משה בן זקן ויו"ר רשות הספנות צדוק רדקר.

ממשרד התחבורה נמסר כי: "הנושא נמצא בטיפול רספ"ן (רשות הספנות והנמלים; ע"ב) מול הנהלת נמל אשדוד".

מחברת נמלי ישראל: "בדיווח שהגיע אל החברה מחברת נמל אשדוד נמסר כי צירוף תקלות שהחל ביום שישי יצר את הסיטואציה. עוד נמסר לחנ"י כי חברת הנמל פועלת במרץ כדי להתגבר על הבעיות הטכניות . בתוך מספר ימים יתוקן מדלה".

מנמל אשדוד נמסר: "הנמל פועל לפריקת הגרעינים ברציפות, תקלה במדלה מטופלת ברגעים אלו, העבודה נמשכת בחופנים".`
            ]
        },

        {
            url: "https://www.themarker.com/dynamo/2023-09-12/ty-article/.premium/0000018a-854d-d16e-a78f-cf5d4d4f0000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "בתוך פחות מחצי שנה: בנמל אשדוד ריסקו מתקן לפריקת תבואות",
            image: "https://img.haarets.co.il/bs/0000018a-854d-d16e-a78f-cf5d4ca70000/47/ff/a4f7f35142388db3bac56791b596/53480611.JPG?&width=1500&height=872&cmsprod",
            publishDate: "12/09/2023",
            author: "עידן בנימין",
            modal: [
                `בסוף השבוע האחרון רוסק בנמל אשדוד אחד מהמתקנים לפריקת תבואות, שעבד רק כחצי שנה. לא נגרמו אבדות בנפש, אבל ההשלכות הכלכליות עדיין לא ברורות.

מתקן הפריקה, המכונה מַדְלֶה, הוא שואב ענקי לפריקת תבואה ישירות מבטן האונייה. הוא אחד משלושה מדלים חדשים שהקים הנמל ונכנסו לשימוש רק השנה. מדלה אחד, הגדול ביותר — יכול לפרוק עד 1,200 טונה תבואה ועדיין לא הושמש. המדלה הנוסף רוסק ביום שישי ולכן פריקת התבואות לחלקה הדרומי של המדינה תלויה במדלה אחד בלבד.

המדלה שקרס הוא חלק ממתקן גדול לפריקת תבואות שנחנך רק בתחילת השנה בנמל אשדוד. במקום חובר גם מסוע שבו הושקעו כ–250 מיליון שקל. מסוע זה יכול לשנע עד 1,800 טונה גרעינים בשעה — בהנחה שמשתמשים במדלה הגדול ובעוד אחד מהמדלים הקטנים. ואולם מבדיקת TheMarker בחודשים האחרונים עולה כי הוא משנע בפועל רק כ-10% מהיכולת המרבית שלו.`,

                {data: "https://img.haarets.co.il/bs/0000018a-8551-d11a-a1ce-fdd9ca4e0000/29/7a/dcca9cc34d0883ad2f41fedd3c8e/43798-2.jpg?&width=1500&height=1002&cmsprod", type: "image"},
                
                `המסוע והמדלים הוקמו כדי לפרוק באופן יעיל את התבואה מהאוניות בנמל אשדוד לתוך ממגורה (מחסן תבואה זמני), הנמצאת במרחק של 2.5 ק"מ משם. המסוע לא עובד בהספקו המלא או לפחות בחצי ממנו בשל התנצחויות בין חנ"י (חברת נמלי ישראל — שאחראית על שטחי הנמל) לבין נמל אשדוד, כמעט מיומה הראשון של פעילות המסוע. בנמל אשדוד טוענים כי הממגורה שמקבלת את התבואה לא עובדת כראוי. לעומת זאת, מצד הממגורה וחנ"י טוענים כי הנמל אינו מקצה מספיק כוח אדם. בנמל מסבירים כי לא כדאי להם להקצות כוח אדם נרחב יותר, מאחר שהממגורה אינה עובדת כראוי.`,

                {data: '"התיקון באחריות נמל אשדוד"', type: "subtitle"},
                
                `בפנייה לחברת נמלי ישראל, שלמדו בה על האירוע רק לפני יומיים, הדגישו כי קו המים אינו באחריות שלהם — ולכן יש לפנות לנמל. אף שהאחריות היא על נמל אשדוד, הפגיעה ביכולת הפריקה של הנמל, שהיה יכול להפעיל שני מדלים במקביל — משפיעה באופן ישיר על הכנסות חברת נמלי ישראל. החברה אמורה להחזיר את ההשקעה העצומה שלה (של כסף ציבורי) על ידי שימוש במסוע.

ממשרד התחבורה נמסר: "האירוע דווח למנהל הנמל ביום שישי. לפי הדיווח, חצי זרוע של מנוף מדלה קרסה מהמנוף לרציף. פריקת הגרעינים נמשכת כרגיל, ולפיכך אין השלכות על העבודה בנמל. עלות הנזק ותיקונו הם באחריות וסמכות חברת נמל אשדוד".

מנמל אשדוד, שקיבל שורה של שאלות על נסיבות האירוע, כולל שאלה לגבי אומדן הנזק שנגרם למדלה, מסרו רק כי "הנמל פועל במסגרת רגולציה קפדנית בתחום הבטיחות והמדלים. פריקת הגרעינים ממשיכה כסדרה".`
            ]
        },

        {
            url: "https://www.themarker.com/dynamo/2024-03-31/ty-article/.premium/0000018e-9433-d9a4-a7bf-dc7b53c90000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "למה נמל אשדוד צריך חשבון טיקטוק? כדי להתמודד עם הביקורת",
            image: "https://img.haarets.co.il/bs/0000018e-944d-d9a4-a7bf-dc5d5dae0000/02/e8/3a36060348f3bb56eb0b2a73f4fa/573705.jpg?&width=1500&height=872&cmsprod",
            publishDate: "31/03/2024",
            author: "עידן בנימין",
            modal: [
                `בסוף השבוע נמל אשדוד פירסם בעיתונות הכתובה מכרז ל"שירותי תקשורת דיגיטלית ברשתות החברתיות". לפי פרטי המרכז, נותן השירות יהיה אחראי על הפעלת חשבונות הטיקטוק, הפייסבוק והאינסטגרם של נמל אשדוד. מסגרת המכרז היא בין 198-100 אלף שקל בשנה (תלוי בהצעת הזוכה), לא כולל מע"מ. הזוכה גם נדרש לסייע בזיהוי ומעקב אחר "שיחות שליליות ושיחות בכלל בנושא נמל אשדוד ברשתות החברתיות ובאתרים". נוסף על המכרז, לנמל כבר יש דובר ומשרד יחסי ציבור.

ספק אם ניטור השיח ברשתות החברתיות יעזור לנמל אשדוד להתמודד עם הביקורת השלילית. רק החודש חשפנו כי עלות שינוע טונה פחם מנמל אשדוד לתחנת הכוח רוטנברג היא 99.8 שקל, ואילו מחדרה - 97.6 שקל (פער של 2.25%). הסיבה לכך, לטענת חברת החשמל, היא זמן ההמתנה של אוניות לפריקת הפחם ועלויות הפריקה בנמל אשדוד.

כך, נוצרה סיטואציה מופרכת שבה כדי לחסוך כספי ציבור עדיף לנסוע 80 ק"מ נוספים, ולהעמיס את הכבישים בכ-170 משאיות מדי יום שנוסעות בסולר, מאשר לפרוק אוניית פחם בנמל אשדוד. שינוע הפחם לתחנת הכוח רוטנברג נדרש לאחר שלפני כשנה קרס מזח הפחם בתחנה מה שחייב פריקת פחם במקום אחר ושינוע שלו על גבי משאיות לתחנה.

בחודש פברואר הצליחו בנמל אשדוד להשבית את מסוע הגרעינים שעלה לציבור כ-250 מיליון שקלים ונועד לפריקת תבואה יעילה יותר. זה קרה לאחר שעובדי הנמל שברו את מתקן הפריקה (וגם את מתקן הגיבוי). בעקבות זאת נדרשו בנמל לעבור לפריקה על גבי משאיות. בכללי, בזמן האחרון, הנמל ממשיך לאבד לקוחות לנמלים הפרטיים.

מאחר וחברות הספנות לא בוחרות את הנמל בו הן עוגנות על בסיס הטיקטוק שמפרסם המנכ"ל, אלא על בסיס השירות שהן מקבלות בנמל - נשאלת השאלה למה נחוץ המכרז החדש.

מנמל אשדוד נמסר "כחברה עסקית חיונית וקיומית, אנו מתקשרים מול מגוון לקוחותינו בכל אמצעי המדיה המגוונים על מנת לכסות את כל סוגי הקהלים".`
            ]
        },

        {
            url: "https://www.themarker.com/dynamo/cars/2024-04-17/ty-article/.premium/0000018e-e755-d0ca-a99f-f77fb1180000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "המתווה לתחרות בנמלים: משרדי האוצר והתחבורה נכנעים לעובדי נמל אשדוד",
            image: "https://img.haarets.co.il/bs/0000018e-e767-dba7-a3ee-ff7f9bb00000/d7/65/1ac435f54919848008b0c7166814/592343.jpg?&width=1500&height=1002&cmsprod",
            publishDate: "17/04/2024",
            author: "מירב ארלוזורוב",
            modal: [
                `המתווה להגברת התחרות בנמלים, שפורסם בשבוע שעבר על ידי משרד התחבורה ושמשרד האוצר תומך במרבית פרטיו, מעורר ביקורת גוברת. עיקר הביקורת היא כי המתווה הוא כניעה לוועד עובדי נמל אשדוד, בידי שרת התחבורה מירי רגב.

המתווה נועד להגיע להסכמה עם כל חמשת הנמלים הפועלים בים התיכון: נמל אשדוד (ממשלתי), נמל חיפה, נמל הדרום, נמל המפרץ ונמל מספנות ישראל — על שינוי המתווה התחרותי ביניהם.

השאיפה להגיע למתווה בהסכמה מעוררת תהייה. המדינה יכולה להורות על שינוי אופן הפעולה של הנמלים. אלא שהשרה רגב מעדיפה להגיע לדברים בהסכמה — כנראה בעיקר ממורא ועד עובדי נמל אשדוד, והשפעתו במרכז הליכוד.

מאחר שמדובר בהצעה למתווה מוסכם, המתווה הוא תן־וקח. כל אחד מהנמלים מקבל משהו ומוותר על דברים אחרים, מתוך רצון לרצות את כולם.`,

                {data: "https://img.haarets.co.il/bs/0000018e-e75b-d7e3-a9bf-f77fdf390000/54/f7/9c0822624076999442db7b4bcaeb/012892-2.jpg?&width=1500&height=844&cmsprod", type: "image"},
                
                `במשרד האוצר תומכים במתווה משום שהוא מביא להגברת התחרות בפריקת מטען כללי (כל מה שאינו מכולות ומכוניות) באמצעות הסרת מגבלת הכמות בפריקת מטען כללי על נמל מספנות ישראל (בבעלות איש העסקים שלומי פוגל, שמזוהה כמקורב לראש הממשלה, בנימין נתניהו) — ובאמצעות מתן היתר קבוע לשני הנמלים החדשים, נמל הדרום ונמל המפרץ, לפרוק מטען כללי.

שני הנמלים החדשים פורקים כיום מטען כללי בהיתר זמני בלבד, וההיתר של נמל המפרץ פג בתחילת השבוע. כך שאם לא תושג הסכמה בימים הקרובים על המתווה, התחרות על פריקת המטען כללי בישראל תיפגע קשות.

הנמלים החדשים הגבירו מאוד את התחרות על פריקת מכולות. התחום שנותר חסום לתחרות הוא מטען כללי, והדבר הביא לתור הגדול של אוניות במהלך הקורונה ובתחילת מלחמת רוסיה־אוקראינה. ההיתר הזמני לנמלים החדשים פתר את בעיית התור, ויצר תחרות גם במטען הכללי, ובמשרדי האוצר והתחבורה רואים חשיבות עליונה בשימור התחרות הזו.

לכן, המתווה כולל הסכמה של הנמלים האחרים לכך ששני הנמלים החדשים יעסקו באופן קבוע בפריקת מטען כללי כבר מעתה. לפי הסכמת הנמלים הוותיקים במתן ההיתר הזמני, הכניסה של הנמלים חדשים למטען כללי אמורה היתה להידחות בכמה שנים.`,

                {data: "https://img.haarets.co.il/bs/0000018e-e767-dba7-a3ee-ff7f9bb00000/d7/65/1ac435f54919848008b0c7166814/592343.jpg?&width=1500&height=1002&cmsprod", type: "image"},
                
                `רק שבתמורה להסכמת הנמלים הוותיקים לפתיחת התחרות בתחום המטען הכללי — כולל הסרת מגבלת כמות הפריקה על נמל מספנות ישראל — משרד התחבורה מציע הטבות לנמל חיפה ולנמל אשדוד. בעוד ההטבות לנמל חיפה נחשבות למקובלות, ההטבות לנמל אשדוד הן מרחיקות לכת ומעוררות ביקורת רבה.

הטבה אחת היא לוותר על נמל העבודה בנמל אשדוד (רציפים 24–25). נמל העבודה הוא העתודה האחרונה שמאפשרת הכנסת מתחרה חדש לנמל — כך שגם בנמל אשדוד יפעלו שלושה נמלים, בדומה למצב בנמל חיפה.

רק שבמסגרת המתווה מציע משרד התחבורה להעביר את רציפים 24–25 לידי נמל אשדוד, כלומר להוריד מהפרק את האפשרות להכנסת תחרות נוספת שם. נמל אשדוד אמור להיות מופרט, וכל הפחתת תחרות ותוספת שטח מוסיפה ערך לנמל, ובכך מגדילה מאוד גם את התמורה שיקבלו העובדים במסגרת ההפרטה. ההערכה היא ש–10%–15% מתמורת ההפרטה תוזרם לעובדים.

משרד האוצר הסתייג מהצעה זו של משרד התחבורה, והוא מציע כי נמל העבודה יועבר לידי נמל אשדוד לחמש שנים בלבד, עד ההפרטה. עם זאת, קשה להניח כי המדינה כבר תתבע חזרה את נמל העבודה לידיה, לאחר שהעבירה אותו לידי עובדי נמל אשדוד, גם אם לכאורה באופן זמני.`,

                {data: "הממגורה וההפרטה", type: "subtitle"},
                
                `משרד התחבורה גם מציע להעביר את כל פריקת הגרעינים לידי נמל אשדוד. כיום עובדי נמל אשדוד עוסקים רק בפריקת הגרעינים ברציף. מסוע העברת הגרעינים לממגורה, והממגורה, נמצאים בבעלות המדינה — לאחר שהושקעו בהם מאות מיליוני שקלים — והממגורה מופעלת בידי זכיין חיצוני.`,

                {data: "https://img.haarets.co.il/bs/0000018e-e760-d0ca-a99f-f77b65980000/b1/3c/fcdbadec4eef8e29396a13501301/39770-2.jpg?&width=1500&height=1002&cmsprod", type: "image"},
                
                `אין עוררין שחלוקת מקטע הגרעינים לשלושה מפעילים פוגעת בפריקת הגרעינים בנמל אשדוד. עלות הפריקה היא 40 שקל לטונה — לעומת 28 שקל בנמל חיפה, שבו מקטע הגרעינים מנוהל בידי זכיין חיצוני אחד. בנוסף, יש הטוענים שהחזקת נמל אשדוד רק בחלק קטן ממקטע הגרעינים גורמת לגרירת רגליים בפריקה.

עם זאת, יש מחלוקת גדולה אם הפתרון הוא העברת כל מקטע הגרעינים להפעלת נמל אשדוד. החשש הוא כי בכך הנמל, שנחשב לא יעיל ואיטי מאוד, מקבל פרס מהמדינה — בלי שהוא נדרש להתייעל ולהשתפר. הממגורה באשדוד אמנם אמורה להתחרות בממגורה בחיפה, שבקרוב תוחלף בממגורה חדשה שנבנית במספנות ישראל — אבל החשש הוא שזה לא יזרז את פריקת הגרעינים באשדוד.

וכאמור, צירוף מקטע הגרעינים לנמל אשדוד מעלה את השווי של הנמל לצורכי הפרטה, וצפוי להגדיל את התמורה שיקבלו עובדי הנמל ממנה. כך שהחשד שמדובר בכניעה פוליטית של השרה רגב לעובדי הנמל, על חשבון יוקר המחיה של המזון בישראל — מנקר.

טענה נוספת נגד המתווה היא שגם שני הנמלים החדשים וגם מספנות ישראל מקבלים מהמדינה הקלות, בניגוד לתנאי הזיכיון המקוריים שלהם. מה שעלול לפגוע ביעילות המכרזים בעתיד.`
            ]
        },

        {
            url: "https://www.themarker.com/dynamo/2024-07-30/ty-article/00000190-ff95-da63-a9d0-fffdc4d10000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "במשרד התחבורה נשענים על נמל אשדוד בחירום — ומתעלמים מפריקת המזון",
            image: "https://img.haarets.co.il/bs/00000190-ff95-da63-a9d0-fffdbe800000/06/ef/d0492ff34c7da23c9da474d5b402/57807628.JPG?&width=1500&height=1000&cmsprod",
            publishDate: "30/07/2024",
            author: "עידן בנימין",
            modal: [
                `בתחילת השבוע הוציא משרד התחבורה הודעה לתקשורת שכותרתה "בהנחיית שרת התחבורה: נמל אשדוד ונמל הדרום נערכים לשמש כנמלים אלטרנטיביים לנמלי הצפון". ההודעה התמקדה בביקורו של מנכ"ל משרד התחבורה, משה בן זקן, בנמל אשדוד — היחיד שנותר בבעלות ממשלתית.
               
                לא ברור עם אילו תובנות יצא בן זקן מנמל אשדוד. גם פנייה למשרד התחבורה בנוגע לביקור מנכ"ל המשרד בנמל לא זכתה להתייחסות. אם בן זקן למד משהו בסיור, זה היה אמור להדיר שינה מעיניו.
               
                התפקוד של נמל אשדוד נמוך באופן חריג — גם בהשוואה לתפקודו בעבר — ובשבועות האחרונים יבואנים וסוכני אוניות מנסים להימנע מהגעה לנמל המידרדר. הבעיה הכי גדולה בהסתמכות על נמל אשדוד כחלופה לנמלי חיפה צפויה להיות בפריקת גרעינים — מצרך חיוני כמעט לכל סוגי המזון שאנחנו אוכלים. בנוסף, החלטה שבן זקן קיבל עשויה לפגוע במוכנות הנמלים הדרומיים למלחמה.
                
                נמל הדרום החדש שבבעלות TIL השווייצית וממוקם ליד נמל אשדוד, מפעיל רציף שבו הוא פורק רק מכולות. עד אפריל, הנמל פרק גם מלט וברזל, שחיוניים לענפי הבנייה והתשתיות. אלא שהרישיון של הנמל להפעלת אותו רציף פג ובמשרד התחבורה בחרו שלא להאריך אותו.
                
                הרישיון ניתן באופן זמני לאחר שלפני כשנתיים נוצר תור של עשרות אוניות בכניסה לנמלים. הפסקה של פעילות הפריקה עשויה לתפוס את מדינת ישראל לא מוכנה אם, כפי שמציין משרד התחבורה, נמלי הדרום אמורים להיערך ולהחליף את נמלי חיפה.
                
                גם אם נמל הדרום לא יפעיל את אותו רציף, שעומד כיום שומם, רצוי שיהיה מי שיפעיל אותו וישקיע בתשתית מתאימה — שתאפשר פריקה מהירה.
                
                בנמל הדרום פרקו ב–2023 0.6 מיליון טונה של מלט, מתוך 4.6 מיליון טונה שנפרקו בכלל הנמלים — כ–13% מהמלט שנפרק.
                
                ברזל ומלט הם מצרכים חיוניים, אבל מה שחיוני יותר לקיומנו הוא מזון. מה שמביא אותנו למציאות העגומה של נמל אשדוד בפריקה של גרעינים. זאת הבעיה שצריכה להדיר שינה מעיניו של מנכ"ל משרד התחבורה בתוכנית הנמלים להסלמה במלחמה בצפון: יכולת פריקת הגרעינים בנמל הדרומי, היחידי שיכול כיום לעשות זאת.
                
                גרעינים הם מוצר הגלם הבסיסי כמעט בכל המזון שאנחנו אוכלים — החל מחיטה ללחם ועד תערובות לבקר ועופות. אפילו סויה, שמשמשת מרכיב מרכזי בחלק גדול מסוגי המזון, מגיעה כגרעינים. אלה מועמסים לאוניות ייעודיות בתפזורת, נפרקים מהן ישירות לממגורות (מכלי אחסון) או למשאיות — ומשם מסופקים ללקוחות או למחסני ביניים.`,

                {
                    data: "https://img.haarets.co.il/bs/00000190-ffd5-d3e8-adbc-ffd720d30001/89/41/947653f744c58b051d2ff7e1ad74/hagariinim-dsk.png?&width=1500&height=583&cmsprod",
                    type: "image"
                },

                `תמיד יש תלונות על נמל אשדוד בתחום זה, אבל בשבועות האחרונים הן מתרבות. כמה סוכני אונייה (בתחומי הגרעינים והמכולות) העידו כי ככל שהם יכולים, תמיד יעדיפו להגיע לנמלי חיפה — שם הם מקבלים שירות טוב בזכות התחרות בין נמל מספנות ישראל לנמל חיפה.`,

                {data: "היכן נפרקים גרעינים?", type: "subtitle"},

                `בישראל יש שלושה נמלים שפורקים גרעינים. שניים בצפון — נמל חיפה ונמל מספנות ישראל — ואחד בדרום, נמל אשדוד. אף שצריכת הגרעינים בישראל מתחלקת שווה בשווה בין הדרום לצפון, כ–75% מפריקת הגרעינים נעשית בנמלי הצפון, ורק כ–25% בנמל אשדוד.
                
                כך, בחצי השנה האחרונה מתוך כ–2.4 מיליון טונה גרעינים שנפרקו, כ–1.8 מיליון טונה נפרקו בנמלי חיפה — ורק 0.6 מיליון טונות נפרקו בנמל אשדוד. ב–2023 נפרקו בסך הכל 5.2 מיליון טונה של גרעינים בנמלי ישראל — כ–4 מיליון טונה בנמלי חיפה ו–1.2 מיליון טונה בנמל אשדוד (24%); וב–2022 נפרקו 5.3 מיליון טונה — 4.5 מיליון טונה בנמלי חיפה ו–0.8 מיליון טונה בנמל אשדוד (15%).

                השינוי בין 2022 ל–2023 נבע בעיקר משיפור טכנולוגי בנמל אשדוד. בינואר 2023 נחנך מסוע הגרעינים באשדוד, שהושקעו בו כ–250 מיליון שקל. המסוע אמור להקל על פריקת חלק מסוגי הגרעינים בנמל ולאזן את פריקת הגרעינים בחופי ישראל — כך שהגרעינים שנפרקים בחיפה לא יצטרכו לנסוע בכבישי ישראל במשאיות עד באר שבע, למשל.
                
                השיפור רחוק מהציפיות וכל עוד לא יפתור משרד התחבורה שורה של בעיות בפריקת הגרעינים בנמל אשדוד — הוא לא יצליח לחצות את רף ה–25%. ואם המלחמה בצפון תסלים ובעלי אוניות יסרבו לפקוד את נמלי חיפה — ייווצר צוואר בקבוק ביבוא מזון לישראל.
                
                לישראל יש אמנם מאגרי חירום לגרעינים, שיכולים לספק מזון לטווח זמן מסוים וסודי. אבל המשמעות בפתיחת מלאי החירום היא שאנחנו אוכלים את הרזרבות של מדינת ישראל — כשמימוש הפוטנציאל של פריקת הגרעינים בנמל אשדוד יכול לכסות את כלל היבוא שלו.`,

                {data: "גרעינים הם מוצר גלם בסיסי כמעט בכל המזון שאנחנו אוכלים, החל מלחם ועד תערובות לבקר ועופות. הם נפרקים לממגורות או מסופקים ישירות ללקוחות", type: "subtitle"},

                {data: "https://img.haarets.co.il/bs/00000190-ff95-da63-a9d0-fffdbe800000/06/ef/d0492ff34c7da23c9da474d5b402/57807628.JPG?&width=1500&height=1000&cmsprod", type: "image"},

                {data: '"אנחנו כל פעם חוטפים בראש"', type: "subtitle"},
                
                `יש כמה סיבות ליכולת פריקת הגרעינים הדלה בנמל אשדוד. הראשונה, השירות הגרוע שהיבואנים טוענים שהם מקבלים בנמל. בשבועות האחרונים התרבו התלונות על כך שהנמל לא עומד בהבטחות שלו לכוח אדם לפריקת אוניות, שמתעכבת ימים ארוכים בנמל.

"לנמל אשדוד היו תקופות טובות ופחות טובות אבל עכשיו חזרנו ממש אחורה. בנינו מרכז לוגיסטי שלנו בעורף נמל אשדוד לפני עשור כי הערכנו שנמל אשדוד יהיה הנמל המרכזי של ישראל. לצערי הרב אנחנו כל פעם חוטפים בראש ונאלצים להביא סחורה מהצפון לדרום במקום לנמל אשדוד שהוא 800 מטר מהמרכז הלוגיסטי שלנו," אומר חן כהן סמנכ"ל חברת ADM מרכזי תעשיות שמייבאת גרעינים להאכלת בעלי חיים.

"המסוע בנמל טוב ללקוחות מסוימים אבל הוא מוסיף עוד כ-13 שקל לפריקה. בפריקה האחרונה ביקשנו לפרוק את האוניה בפריקה ישירה. זו אוניה של 7,000 טונה שבנמל רגיל הייתה נפרקת בתוך יום וחצי. בנמל אשדוד לוקח לה שבועיים. אני מנסה להבין איך לצאת מהפלונטר הזה", מוסיף כהן. "אם אשדוד היה עובד באופן נורמלי לקוחות בדרום היו יכולים לחסוך כסף", מסכם כהן. לגרסתו של כהן יש תימוכין גם מיבואנים נוספים.

סיבה נוספת היא תקלות חוזרות ונשנות במדלים (שואבי גרעינים) שמפעיל הנמל שעובדים ומושבתים לסירוגין בגלל תאונות ותקלות שונות.

בנוסף, נמל אשדוד לא עובד בשבת. המשמעות היא שכיום וחצי בשבוע אין פעילות נמלית. מדובר גם בבזבוז משאב ציבורי אדיר מאחר שהנכס הכי חשוב לנמל הוא קו המים שלו. כשקו המים לא ממוצה עד תומו אז הממשלה נדרשת להאריך את קו החוף לנמלים כדי לספק את צרכי המשק.

סיבה רביעית, שלא קשורה לנמל, היא המצב הירוד של הממגורות, שמתופעלות כיום על ידי לוגיסטיקר. הממגורות נמצאות במצב תחזוקה ירוד ופעמים רבות הפריקה באמצעות המסוע נעצרת בגלל תקלה בממגורה. חברת נמלי ישראל צריכה להשקיע כסף בשיפוץ ושדרוג שלה כדי שתוכל לעמוד בקצב המסוע. זה לא קרה בשנתיים האחרונות מאז שהמסוע נכנס לפעולה.

מחברת נמלי ישראל נמסר: "חברת נמלי ישראל תשקיע עשרות מיליוני שקלים בפיתוח ותפעול רציפי הגרעינים בחיפה ובאשדוד כולל דגון והממגורה החל מהשנה הבאה."

מחברת נמל אשדוד נמסר: "חברת נמל אשדוד שיפרה את כל מדדי השירות שלה בנושאי הגרעינים זו השנה השלישית ברציפות. שלושת המדלים שמפעיל הנמל, כמו גם מסוע הגרעינים, תקינים ועובדים היטב ובהיבט זה יש אף יתירות כלים. רמת השירות עלתה בנמל לאור כך שרציף 23 (רציף הגרעינים — ע.ב) הוא רציף יעודי החל משלב הפעלת המסוע. המשמעות היא שאוניות גרעינים המיועדות לרציף זה עוקפות את התור התפעולי כך שהפעילות רציפה ובמגמת עלייה.

"חשוב לציין שהנמל מטפל בכ–20 אוניות ביממה עם מטענים מגוונים. טבעי שבימי עומס אוניות הממוקמות בסוף התור התפעולי לעיתים ימתינו. יש לזכור שלמדינה יש עודף קיבולת רציפים ולכן כל המטענים נתונים לתחרות עזה בין הנמלים ומתועדפים בהתאם לכך. נמל אשדוד נותן מענה לכל ביקוש קיים אך גם בהתחשב במצב התחרות בין הנמלים. בשיא הביקושים הנמל פרק עד 24 מיליון טון מטענים, ועם הפעלת הנמלים החדשים הוא ירד לאזור ה–20 מיליון טון, כך שהמענה לביקושים הוא טוב".

ממשרד החקלאות נמסר: "מלאי החיטה של מדינת ישראל כולל כמויות גדולות יותר מאשר נדרש בתרחיש הייחוס. מדובר במלאי 'נושם', ממנו מייצרים בשגרה את הלחם. משרד החקלאות מנהל את מלאי החירום של חיטה למאכל אדם ומספוא להזנת בעלי חיים. במקביל, המשרד מקדם חקיקה לקביעת הסדרי דיווח ופיקוח על המלאי התפעולי של גרעיני חיטה למאכל ורכיבי מזון לבעלי חיים כדי לנהל נכון את המלאי התפעולי".`
            ]
        },

        {
            url: "https://www.zman.co.il/live/524817/",
            title: "יבואנים: \"נמל אשדוד לא יכול לספק את צרכי המזון בחירום במקום חיפה\"",
            publishDate: "25/09/2024",
            image: "https://static.zman.co.il/www/uploads/2022/09/F180802AKFF01-1024x640.jpg",
            author: "תני גולדשטיין"
        },

        {
            url: "https://www.themarker.com/dynamo/2024-11-17/ty-article/.premium/00000193-3985-de28-abdb-b9856d6c0000",
            title: "אם מפעל הסויה והנמל נמצאים באשדוד - למה לפרוק את הסחורה בחיפה?",
            image: "https://img.haarets.co.il/bs/00000193-3a07-db66-aff3-7ea748790000/18/e5/801cdd9040d8840b0bf3cd8a10d2/126988.jpg?precrop=2400,1395,x0,y201&width=1500&height=872&cmsprod",
            publishDate: "17/11/2024",
            author: "עידן בנימין",
            modal: [
                `בחודש אוקטובר נפרקו בנמל אשדוד רק 36 אלף טונה של גרעינים — צניחה של כ-82% ביחס לספטמבר (199 אלף טונה) וירידה של כ-35% (55 אלף טונה) ביחס לאוקטובר 2023 — החודש שבו פרצה המלחמה, אז אוניות חששו לעגון באשדוד.

"גרעינים" הם שם כולל למוצרי תבואה שונים שמשמשים גם בעלי חיים וגם בני אדם. אחד מסוגי הגרעינים הוא פולי סויה. סולבר — היבואן הגדול בישראל של פולי סויה (ייבא כ-300 אלף טונה ב-2023) — מעבד את הסויה במפעל שנמצא באשדוד. לכן, רק הגיוני שגם יפרוק את הסחורה בנמל אשדוד. אלא שזה לא מה שקורה בפועל.

בגלל השירות האיטי בנמל אשדוד, סולבר פורק חלק מהסויה בממגורות (מבנים לאחסון גרעינים) דגון בחיפה, ומשם משנע במשאיות את הסויה למפעל באשדוד - שנמצא במרחק 130 ק"מ מחיפה. לפי הערכות במגזר העסקי, בשנה האחרונה פרק סולבר כ-40 אלף טונה מתוך כ-200 אלף בנמלי חיפה, וכ-1,000 משאיות שינעו אותם לאשדוד. מעבר לעומס שיוצרות המשאיות בכבישי ישראל, השינוע מייצר שני הפסדים משמעותיים: האחד —הפסד ליבואן שמתגלגל בסופו של דבר ללקוחות. השני — הפסד של יותר ממיליון שקל לנמל אשדוד הממשלתי.`,

                {data: "https://img.haarets.co.il/bs/00000193-3a0c-da32-a1df-7f3fc8650000/54/9f/95873e354ddd94639bd5f067c289/126987.jpg?&width=1500&height=1500&cmsprod", type: "image"},
                
                `כשפולי הסויה כן נפרקים בנמל אשדוד, הם נפרקים באמצעות מנופים, והיבואן מצדו מוכן לספוג את קצב הפריקה האיטי — שכרוך בעלויות גבוהות יותר בגלל עיכוב האונייה. עם זאת, קצב העבודה האיטי יוצר לעתים פקק בנמל, ואז הוא שולח מראש את האונייה לחיפה. בממגורות דגון בחיפה קצב הפריקה מהיר יותר בזכות מתקני המדלים (שואבי ענק), שמכפילים את מהירות הפריקה. גם באשדוד קיים מתקן כזה, אבל עד היום הוא לא שימש לפריקת סויה.
                
                לפני כשנתיים נחנך בנמל אשדוד מסוע לפריקת גרעינים, שנועד לחבר את הממגורה —שנמצאת כשני קילומטר מהנמל — לקו הים בנמל. אל המסוע חוברו מדלים שיכולים לפרוק את רוב סוגי הגרעינים, כולל סויה, בקצב של כ-1,000 טונה בשעה. המשמעות: אונייה שנכנסת לנמל בבוקר יכולה לצאת משם כבר למחרת בשעות הצהריים.

המסוע, שעלותו לציבור הגיעה לכ-250 מיליון שקל, חולקה בין שתי חברות ממשלתיות: חברת נמלי ישראל ונמל אשדוד. עם זאת, אף שעברו שנתיים מאז שנחנך המסוע והתחיל לעבוד, עד כה לא נפרקו באמצעותו פולי סויה — ולא ברורה הסיבה לעיכוב. בתגובה לפניית TheMarker בסוף השבוע, בנמל אשדוד לא היו מעוניינים להגיב. הבוקר (ראשון) החל בנמל ניסוי ראשון לפרוק סויה באמצעות המסוע.`,

                {data: "https://img.haarets.co.il/bs/00000193-3a8c-db66-aff3-7eafc4150001/db/fe/5d4da84d4cc6a43a902b02d08e4a/kamut-desk.png?&width=1500&height=812&cmsprod", type: "image"},
                
                `בחלוקת צריכת הגרעינים בישראל, כמחצית מהגרעינים בישראל נצרכים בדרום וכמחצית בצפון. עם זאת, מתוך כ-4.2 מיליון טונה גרעינים בסך הכל שנפרקו בנמלי ישראל בין ינואר לאוקטובר, רק כרבע (1,017) נפרקו בדרום. הסיבה לכך כאמור היא הקושי של נמל אשדוד להפעיל כראוי את מסוע הגרעינים בנמל, קושי שמחייב שינוע של כמיליון טונה מנמלי חיפה דרומה. בשעת הצורך, אם ייסגרו נמלי חיפה — נמל אשדוד לא יוכל לתת מענה ראוי לייבוא גרעינים לישראל.`
            ]
        },

        {
            url: "https://soundcloud.com/galey-israel/25-09-24a-6",
            title: "השיטה עם איציק מצרפי - דקה 12:33",
            image: "/other/HaShita.png",
            publishDate: "25/09/2024",
            author: "איתי רון",
            audio: "/audio/HaShita.mp3",
            type: "audio"
        },
    ]
}


const Neurology2024: Collection = {
    title: "האיגוד הנוירולוגי בישראל",
    client: Neurology,
    items: [
        {
            url: "https://news.walla.co.il/item/3712757",
            title: "דוח השבץ 2022: טיפול לא אחיד ופערים מדאיגים במענה לחולים בישראל",
            author: "אביחי חיים",
            publishDate: "18/12/2024",
            image: "https://images.wcdn.co.il/f_auto,q_auto,w_1400/3/8/1/7/3817349-46.jpg"
        },
        {
            url: "https://www.ynet.co.il/health/article/yokra13981966",
            title: "מחסור חריף במצנתרי מוח בדרום ובצפון: \"אמא נותרה משותקת, יש כעס ותסכול\"",
            author: "אדיר ינקו",
            publishDate: "02/07/2024",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2023/05/21/BJ7sEkldr2/BJ7sEkldr2_0_0_1000_563_0_x-large.jpg"
        },
        {
            url: "https://www.ynet.co.il/health/article/yokra13618223",
            title: "שבועיים בלי מצנתר מוח בדרום: כך הופקרו תושבי הפריפריה | מעקב",
            author: "אדיר ינקו",
            publishDate: "05/10/2023",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2023/10/05/B1W4RRnjxT/B1W4RRnjxT_0_0_1000_563_0_x-large.jpg"
        },
        {
            url: "https://www.bhol.co.il/news/1656782",
            title: "תחלואה נוירולוגית היא הגורם מספר 1 בעולם לתחלואה ולנכות",
            author: "מאיר גלבוע",
            publishDate: "17/03/2024",
            image: "https://storage.bhol.co.il/media/lt/1652664/gallery/683644_tumb_800X480.jpg"
        },
        {
            url: "https://www.inn.co.il/news/632236",
            title: "ארגון הבריאות העולמי: תחלואה נוירולוגית - הגורם המוביל לנכות",
            publishDate: "17/03/2024",
            author: "מערכת חדשות ערוץ 7",
            image: "https://a7.org/files/pictures/781x439/1078211.jpg"
        },
        {
            url: "https://www.mivzaklive.co.il/archives/786929",
            title: "ארגון הבריאות העולמי: זה הגורם מספר 1 בעולם לתחלואה ונכות",
            author: "יובל אביב",
            publishDate: "17/03/2024",
            image: "/other/neurology.png"
        },
        {
            url: "https://doctorsonly.co.il/2024/03/312283/",
            title: "ה-WHO: מחלות נוירולוגיות הן הגורם מס' 1 בעולם לתחלואה ולנכות",
            image: "https://cdn.doctorsonly.co.il/2019/06/%D7%A1%D7%99%D7%9B%D7%95%D7%9F-%D7%9C%D7%A9%D7%91%D7%A5-%D7%9E%D7%95%D7%97%D7%99.-%D7%90%D7%99%D7%9C%D7%95%D7%A1%D7%98%D7%A8%D7%A6%D7%99%D7%94-992x558.jpg",
            author: "מערכת דוקטורס אונלי",
            publishDate: "18/03/2024"
        },
        {
            url: "https://doctorsonly.co.il/2024/03/312788/",
            title: "30 מומחים בלבד ל-90 אלף חולי אפילפסיה בישראל",
            image: "https://cdn.doctorsonly.co.il/2019/04/%D7%91%D7%93%D7%99%D7%A7%D7%AA.-%D7%90%D7%99%D7%9C%D7%95%D7%A1%D7%98%D7%A8%D7%A6%D7%99%D7%94-EEG-%D7%91%D7%99%D7%9C%D7%93%D7%94-%D7%97%D7%95%D7%9C%D7%AA-%D7%90%D7%A4%D7%99%D7%9C%D7%A4%D7%A1%D7%99%D7%94-992x558.jpg",
            author: "מערכת דוקטורס אונלי",
            publishDate: "25/03/2024"
        },
        {
            url: "https://www.hayadan.org.il/%D7%AA%D7%97%D7%9C%D7%95%D7%90%D7%94-%D7%A0%D7%95%D7%99%D7%A8%D7%95%D7%9C%D7%95%D7%92%D7%99%D7%AA-%D7%94%D7%99%D7%90-%D7%94%D7%92%D7%95%D7%A8%D7%9D-%D7%9E%D7%A1%D7%A4%D7%A8-1-%D7%91%D7%A2%D7%95%D7%9C",
            title: "תחלואה נוירולוגית היא הגורם מספר 1 בעולם לתחלואה ולנכות, עם למעלה מ-3 מיליארד איש עם מחלות מוח",
            image: "https://www.hayadan.org.il/images/content3/2024/03/Depositphotos_616587310_L.jpg.webp",
            author: "אבי בליזובסקי",
            publishDate: "18/03/2024"
        },
        {
            url: "https://103fm.maariv.co.il/programs/media.aspx?ZrqvnVq=JHJDHF&c41t4nzVQ=ELD",
            title: "\"יש טיפולים מצוינים היום, לא לפחד לספר על המחלה\"",
            author: "ענת דוידוב",
            publishDate: "25/03/2024",
            image: "https://103fm.maariv.co.il/download/highlight/SegmentImgNew_646042_43_34_.jpg"
        },
        {
            url: "https://ini.co.il/archives/3824",
            title: "לקראת יום המודעות לאפילפסיה: הנתונים המלאים על המחלה",
            publishDate: "25/03/2024",
            author: "מערכת ini",
            image: "/other/img26.png"
        },
        {
            url: "https://www.ynet.co.il/health/article/yokra13856006",
            title: "פצועי מלחמה רבים חוו זעזוע מוח: \"הזיכרון אבד, כשהתעוררתי מההרדמה הרבצתי וקיללתי\"",
            author: "אדיר ינקו",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/03/26/HybBNCk1A/HybBNCk1A_102_0_2272_1278_0_x-large.jpg",
            publishDate: "26/03/2024"
        },
        {
            url: "https://www.ice.co.il/health/news/article/1006260",
            title: "ברקע מותו של עומר דץ: זו המחלה שמאיימת על עשרות אלפי ישראלים",
            image: "https://img.ice.co.il/giflib/news/rsPhoto/sz_432/rsz_615_346_29ice28112023.jpg",
            publishDate: "25/03/2024",
            author: "מערכת ICE",
        },
        {
            url: "https://www.bhol.co.il/news/1657513",
            title: "עשרות אלפי חולים בארץ ופחות מ-30 מומחים: הכל על מחלת האפילפסיה",
            author: "קובי ברקת",
            image: "https://storage.bhol.co.il/media/lt/1652780/gallery/686134_tumb_800X480.jpg",
            publishDate: "25/03/2024"
        },
        {
            url: "https://doctorsonly.co.il/2024/03/312872/",
            author: "מערכת דוקטורס אונלי",
            publishDate: "26/03/2024",
            title: "60% מפצועי המלחמה בשיקום סובלים מתסמונת פוסט זעזוע מוח",
            image: "https://cdn.doctorsonly.co.il/2024/03/F-JNfyfXkAACw7J-640x360.jpeg"
        },
        {
            url: "https://apchi.co.il/?p=15747",
            title: "התקף אפילפטי: מה הסיבות, איך לזהות, ומה חשוב לעשות?",
            image: "https://apchi.co.il/wp-content/uploads/2024/03/WhatsApp-Image-2024-03-25-at-09.33.36.jpeg",
            publishDate: "26/03/2024",
            author: "שרהל'ה סנגאוי"
        },
        {
            url: "https://doctorsonly.co.il/2024/06/318782/",
            title: "אושרו להקמה שתי חברות בת של האיגוד הנוירולוגי בישראל",
            image: "https://cdn.doctorsonly.co.il/2024/06/2161c6a8-5053-4920-8b9c-36d9570c5314-e1728973079271-992x558.jpeg",
            publishDate: "19/06/2024",
            author: "מערכת דוקטורס אונלי"
        },
        {
            url: "https://apchi.co.il/?p=16260",
            title: "המהפכה במוח: שתי חברות-בת חדשות לאיגוד הנוירולוגי",
            image: "https://apchi.co.il/wp-content/uploads/2024/06/WhatsApp-Image-2024-06-19-at-08.27.55-e1718776332365.jpeg",
            publishDate: "19/06/2024",
            author: "שרהל'ה סנגאוי"
        },
        {
            url: "https://www.bhol.co.il/news/1666578",
            title: "בשורה לחולים: הבנה מעמיקה יותר של מחלת האלצהיימר ומיפוי הגורמים לה",
            image: "https://storage.bhol.co.il/articles/42780_tumb_800X480.jpg",
            author: "אלי קליין",
            publishDate: "28/06/2024"
        },
        {
            title: "חדש בישראל: בדיקת דם לאיבחון אלצהיימר – שנים לפני פרוץ התסמינים | פרסום ראשון",
            url: "https://www.maariv.co.il/news/health/article-1110848",
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/892826",
            publishDate: "28/06/2024",
            author: "ד\"ר איתי גל",
        },
        {
            title: "הקריטריונים החדשים לאבחון אלצהיימר כוללים גם אבחון מבוסס ביומרקרים",
            url: "https://www.e-med.co.il/specialties/family-medicine/2024/06/28/583295",
            image: "/other/img27.png",
            publishDate: "28/06/2024",
            author: "מערכת e-med",
        },
        {
            title: "פורסמו קריטריונים רשמיים מעודכנים לאבחון מחלת אלצהיימר",
            url: "https://doctorsonly.co.il/2024/06/319629/",
            author: "מערכת דוקטורס אונלי",
            publishDate: "30/06/2024",
            image: "https://cdn.doctorsonly.co.il/2017/01/shutterstock_381825145-992x558.jpg"
        },
        {
            title: "\"תחילתה של מהפכה באבחון אלצהיימר\": השינוי שמסעיר את הנוירולוגים",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/04/16/Sy70g1TseC/Sy70g1TseC_0_87_1000_563_0_x-large.jpg",
            url: "https://www.ynet.co.il/health/article/sjsjlk0l0",
            author: "רון קריסי",
            publishDate: "01/07/2024"
        },
        {
            title: "מחסור חמור במצנתרי מוח בפריפריה",
            url: "https://doctorsonly.co.il/2024/07/320813/",
            image: "https://cdn.doctorsonly.co.il/2024/07/shutterstock_762052222-992x558.jpg",
            publishDate: "02/07/2024",
            author: "מערכת דוקטורס אונלי"
        },
        {
            url: "https://www.makorrishon.co.il/news/769491/",
            title: "פריצת דרך בטיפול באלצהיימר: תרופה שמאטה את ההידרדרות\n",
            image: "https://www.makorrishon.co.il/wp-content/uploads/2019/03/alzhemiers-80767476-4cd7-11e9-9663-00ac73f49662-750x375.jpg",
            publishDate: "04/07/2024",
            author: "אלינור פבריקר"
        },
        {
            url: "https://doctorsonly.co.il/2024/07/322544/",
            title: "\"יש לנו הכלים וטכנולוגיות, אבל אין לנו נוירולוגים מומחים לטפל בחולים\"",
            image: "https://cdn.doctorsonly.co.il/2019/06/%D7%A1%D7%99%D7%9B%D7%95%D7%9F-%D7%9C%D7%A9%D7%91%D7%A5-%D7%9E%D7%95%D7%97%D7%99.-%D7%90%D7%99%D7%9C%D7%95%D7%A1%D7%98%D7%A8%D7%A6%D7%99%D7%94-992x558.jpg",
            author: "מערכת דוקטורס אונלי",
            publishDate: "18/07/2024"
        },
        {
            url: "https://healthy.walla.co.il/item/3679445",
            title: "כמחצית מנפגעי השבץ המוחי בישראל לא מקבלים טיפול מיטבי בבתי החולים",
            image: "https://images.wcdn.co.il/f_auto,q_auto,w_1200,t_54,f_webp/3/1/2/9/3129870-46.jpg",
            publishDate: "23/07/2024",
            author: "דורון קופרשטין"
        },
        {
            title: "המחדל המתמשך: קיבלתם שבץ מוחי? עדיף שזה יקרה במרכז הארץ",
            url: "https://www.ynet.co.il/health/article/by0o8wsuc",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/07/23/S1NjOQA2dA/S1NjOQA2dA_0_0_1000_667_0_x-large.jpg",
            publishDate: "23/07/2024",
            author: "אדיר ינקו"
        },
        {
            title: "שתי חברות איגודיות חדשות הוקמו תחת האיגוד הנוירולוגי בישראל",
            url: "https://doctorsonly.co.il/2024/10/328718/",
            publishDate: "15/10/2024",
            author: "מערכת דוקטורס אונלי",
            image: "https://cdn.doctorsonly.co.il/2022/12/shutterstock_677115007-992x558.jpg"
        },
        {
            title: "התכנית הלאומית לטיפול ולמניעת נזקי שבץ מוחי כשלה",
            url: "https://doctorsonly.co.il/2024/07/322808/",
            image: "https://cdn.doctorsonly.co.il/2020/06/%D7%99%D7%97%D7%99%D7%93%D7%94-%D7%9C%D7%98%D7%99%D7%A4%D7%95%D7%9C-%D7%91%D7%A9%D7%91%D7%A5-%D7%9E%D7%95%D7%97%D7%99.-%D7%90%D7%99%D7%9C%D7%95%D7%A1%D7%98%D7%A8%D7%A6%D7%99%D7%94-992x558.jpg",
            publishDate: "23/07/2024",
            author: "מערכת דוקטורס אונלי"
        },
        {
            title: "שתי חברות איגודיות חדשות הוקמו תחת האיגוד הנוירולוגי בישראל",
            url: "https://mednet.co.il/2024/10/4748462/?hilite=%D7%A9%D7%97%D7%A8+%D7%93%D7%95%D7%93+%D7%98%D7%A0%D7%94",
            image: "https://cdn.mednet.co.il/2024/07/shutterstock_1887407860-992x558.jpg",
            publishDate: "15/10/2024",
            author: "מערכת מדנט"
        },
        {
            title: "הנחיות חדשות למניעת שבץ מוחי: \"מרבית המקרים ניתנים למניעה\"",
            url: "https://www.maariv.co.il/news/health/article-1142058",
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/940062",
            publishDate: "22/10/2024",
            author: "ד\"ר איתי גל",
        },
        {
            title: "Up to 80% of strokes are preventable with lifestyle changes, new guidelines suggest",
            url: "https://www.jpost.com/health-and-wellness/article-825736",
            image: "https://images.jpost.com/image/upload/q_auto/c_fill,g_faces:center,h_537,w_822/622402",
            author: "DR. ITAY GAL",
            publishDate: "22/10/2024",
            english: true
        },
        {
            title: "על שבץ מוחי- מידע חיוני לציבור מטעם האיגוד הישראלי לנוירולוגיה והחברה הישראלית לשבץ מוחי.",
            url: "https://www.rinunim.co.il/Article.php?CatID=&Page=&ArticleID=58409",
            image: "https://www.rinunim.co.il/Pics/2024/20241030091018-s.jpg",
            publishDate: "30/10/2024",
            author: "רונית קיטאי"
        },
        {
            title: "בצל מות השחקן יעקב כהן: האם אפשר לנצח את מחלת הפרקינסון?",
            url: "https://apchi.co.il/?p=17028",
            image: "https://apchi.co.il/wp-content/uploads/2024/11/WhatsApp-Image-2024-11-03-at-09.05.00.jpeg",
            publishDate: "03/11/2024",
            author: "שרהל'ה סנגאוי"
        },
        {
            title: "אלמנתו של יעקב כהן ז\"ל במסר: \"הוא לא נפטר מפרקינסון, המשיכו לטפל במחלה\"",
            image: "https://www.israelhayom.co.il/wp-content/uploads/2024/11/02/02/partush-220511-136_2-960x640.jpg",
            url: "https://www.israelhayom.co.il/health/article/16714869",
            publishDate: "03/11/2024",
            author: "מיטל יסעור בית-אור"
        },
        {
            url: "https://ini.co.il/archives/4752",
            title: "לאחר מתות של השחקן יעקב כהן: פרטים על פרקינסון בישראל",
            image: "/other/img25.png",
            publishDate: "03/11/2024",
            author: "מערכת ini"
        },
        {
            title: "מדדי CT פרפוזיה מנבאים הצלחת צנתורי מוח לאחר שבץ מוחי",
            url: "https://doctorsonly.co.il/2024/11/330964/",
            publishDate: "28/11/2024",
            author: "מערכת דוקטורס אונלי",
            image: "https://cdn.doctorsonly.co.il/2024/11/11-1-640x360.jpg"
        },
        {
            title: "הלך לעולמו פרופ' ניר גלעדי, מבכירי הנוירולוגים בישראל ובעולם",
            url: "https://doctorsonly.co.il/2024/12/333274/",
            image: "https://cdn.doctorsonly.co.il/2024/11/3333-e1730809500112-640x360.jpeg",
            publishDate: "11/12/2024",
            author: "מערכת דוקטורס אונלי"
        },
        {
            title: "מבכירי הנוירולוגים בישראל: פרופ' ניר גלעדי נפטר בגיל 68",
            url: "https://www.israelhayom.co.il/health/article/16945538",
            image: "https://www.israelhayom.co.il/wp-content/uploads/2024/12/11/11/27934_IMG_6770_2014-03-23-960x640.jpg",
            author: "מיטל יסעור בית-אור",
            publishDate: "11/12/2024"
        },
        {
            title: "מחדל השבץ המוחי: למה רק חצי מהחולים מגיעים באמבולנס?",
            url: "https://apchi.co.il/?p=17299",
            image: "https://apchi.co.il/wp-content/uploads/2023/12/WhatsApp-Image-2023-12-16-at-12.30.01.jpeg",
            publishDate: "18/12/2024",
            author: "שרהל'ה סנגאוי"
        },

    ]
}



export const allCollections = (): CollectionList => {
    return [
        {id: "md2024", collection: MedicalDream},
        {id: "ne2024", collection: Neurology2024},
        {id: "ls2025", collection: SmallSteps2025},
        {id: "ga2024", collection: GrainImporters2024},
        {id: "ls2024", collection: SmallSteps2024part1},
        {id: "ac2024", collection: AllergyCommunication2024},
        {id: "l2023-24d", collection: LungsDoctors2024merged},
    ] as CollectionList;
}


export default function getCollection(path: string): CollectionListItem | null {
    for (const collectionItem of allCollections()) {
        const {id} = collectionItem;

        if (path === id)
            return collectionItem;
    }

    return null;
}


export const getCollectionDates = (collection: Collection): string => {
    const items = collection.items;
    // find the oldest and newest dates
    items.sort((a, b) => {
        const dateA = a.publishDate.split("/").reverse().join("");
        const dateB = b.publishDate.split("/").reverse().join("");

        return dateA.localeCompare(dateB);
    });

    const oldest = items[0].publishDate;
    const newest = items[items.length - 1].publishDate;

    // 06/09/2023 - 28/09/2024
    const [, oldestMonth, oldestYear] = oldest.split("/");
    const [, newestMonth, newestYear] = newest.split("/");

    const monthToText = (month: string): string => {
        const months = ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];
        return months[parseInt(month) - 1];
    }

    const oldestMonthText = monthToText(oldestMonth);
    const newestMonthText = monthToText(newestMonth);

    const oldestText = `${oldestMonthText} ${oldestYear}`;
    const newestText = `${newestMonthText} ${newestYear}`;
    return `${oldestText} - ${newestText}`;
}
