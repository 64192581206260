import getCollection, {getCollectionDates} from "../Database/Database";
import {Client, CollectionListItem} from "./Types";
import {Helmet} from "react-helmet";
import {Article} from "./Article";
import {useEffect} from "react";
import {Text} from "../Global";


const desc = (date: string, cl: Client) => {
    const collection_ = "לקט תקשורת";
    const for_ = "עבור"
    const text_ = "הלקט מכיל כתבות ופרסומים שונים שהתפרסמו במגוון עיתונים ואתרי חדשות."

    return `${collection_} ${date} ${for_} ${cl.name} | ${text_}`
}


export const Collection = ({collectionItem}: { collectionItem: CollectionListItem }) => {
    const {collection} = collectionItem;
    const cl = collection.client;

    const items = collection.items;

    items.sort((a, b) => {
        const dateA = a.publishDate.split("/").reverse().join("");
        const dateB = b.publishDate.split("/").reverse().join("");

        return dateA.localeCompare(dateB) || a.title.localeCompare(b.title);
    });

    const date = getCollectionDates(collection);


    const description = document.getElementById("description") as HTMLMetaElement;
    description.content = desc(date, cl);


    function scrollUp() {
        window.scrollTo({top: 0, behavior: "smooth"});
    }


    useEffect(() => {
        window.addEventListener("scroll", () => {
            const goUp = document.getElementById("go-up") as HTMLDivElement;

            if (window.scrollY > window.innerHeight) {
                goUp.classList.add("go-up-visible");
                goUp.classList.remove("go-up-hidden");
            } else {
                goUp.classList.add("go-up-hidden");
                goUp.classList.remove("go-up-visible");
            }
        });
    }, []);


    return <div className="collection">
        <Helmet>
            <title>{"לקט תקשורת | "}{collection.title}</title>
            <meta name="description" content={desc(date, cl)}/>
            <link rel="icon" href={cl.favicon || cl.logo} type="image/png"/>
            <link rel="apple-touch-icon" href={cl.favicon || cl.logo} type="image/png"/>
        </Helmet>

        <header className="section">
            <div className="logos">
                <img className="shalevpr-logo" src="/assets/full-logo.png" onClick={() => window.open("https://shalevpr.icu")} alt="לוגו"/>
                <img className="client-logo" title={cl.name} src={cl.logo} onClick={() => cl.url.length && window.open(cl.url)} alt="לוגו"/>
            </div>

            <h1>{"לקט תקשורת - "}{collection.title}</h1>

            <span>{date}</span>
        </header>

        <main>
            <span>כדי לפתוח את הכתבה, לחצו עליה.</span>

            <div className="articles">
                {items.reverse().map((item, index) => {
                    return <Article key={index} item={item} i={index}/>
                })}
            </div>
        </main>

        <footer>
            <div onClick={() => window.open("https://shalevpr.icu")}>
                <img className="client-logo" src="/assets/logo.png" alt="לוגו"/>
                <span>{Text.Header.companyName}</span>
            </div>

            <div onClick={() => collection.client.url !== "" && window.open(collection.client.url)} title={collection.client.name}>
                <img src={collection.client.logo} alt="לוגו" title={collection.client.name}/>
                <span>{collection.client.name}</span>
            </div>
        </footer>

        <div id="go-up" className="go-up" onClick={scrollUp}>
            <img src="/icons/arrow-up.svg" alt="חזרה למעלה"/>
        </div>
    </div>
}


export const CollectionReport = ({id}: { id: string }) => {
    // In this component, we will display the full collection on an A4 page (landscape), then the user can print it.
    const collection = (getCollection(id) as CollectionListItem).collection;
    const items = collection.items;
    const cl = collection.client;

    items.sort((a, b) => {
        const dateA = a.publishDate.split("/").reverse().join("");
        const dateB = b.publishDate.split("/").reverse().join("");

        return dateA.localeCompare(dateB) || a.title.localeCompare(b.title);
    });

    const date = getCollectionDates(collection);


    const description = document.getElementById("description") as HTMLMetaElement;
    description.content = desc(date, cl);


    const getItems = (page: number) => {
        const PAGE_WIDTH = 7;
        const PAGE_HEIGHT = 3;
        const ITEMS_PER_PAGE = PAGE_WIDTH * PAGE_HEIGHT;

        let i = items.reverse();

        const start = (page - 1) * ITEMS_PER_PAGE;
        const end = start + ITEMS_PER_PAGE;

        i = i.slice(start, end);

        // i.forEach(item => {
        //     if (item.author == "מערכת דוקטורס אונלי")
        //         item.author = "מערכת האתר";
        //
        //     if (item.author.includes("מיטל יסעור"))
        //         item.author = "מיטל יסעור";
        // });

        return i;
    }


    const href = window.location.href;
    const page = href.split("/").pop();
    const pageNumber = parseInt(page || "1");

    const title = () => {
        // {"לקט תקשורת - "}{collection.title}
        const title_ = collection.title;
        const leket_ = "לקט תקשורת";
        const page_ = "עמוד";

        if (pageNumber > 1)
            return `${leket_} ${title_} - ${page_} ${pageNumber}`;

        return `${leket_} ${title_}`;
    }

    return <div className="collection-report">
        <Helmet>
            <title>{"לקט תקשורת | "}{collection.title}</title>
            <meta name="description" content={desc(date, cl)}/>
            <link rel="icon" href={cl.favicon || cl.logo} type="image/png"/>
            <link rel="apple-touch-icon" href={cl.favicon || cl.logo} type="image/png"/>
        </Helmet>

        <header>
            <img src={cl.logo} alt="לוגו"/>
            <img src="/assets/full-logo.png" alt="לוגו"/>

            <h1>{title()}</h1>
            <h2>{date}</h2>
        </header>

        <main>
            {getItems(pageNumber).map((item, index) => {
                return <Article key={index} item={item} i={index}/>
            })}
        </main>
    </div>
}
