import ArticleModalComponent from "./ArticleModal";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import React, {useState} from "react";
import {Item, ItemTypes} from "./Types";


// a function to get the name of the newspaper
const GetNewspaper = (url_: string): string => {
    if (!url_)
        console.log("No URL", url_);
    
    const url = url_.replace("www.", "");

    const domain = url.split("/")[2];
    const domainName = domain.split(".")[0];
    
    switch (domainName) {
        case "medpage":
            return "MedPage";

        case "apchi":
            return "אפצ'י";

        case "doctorsonly":
            return "דוקטורס אונלי";

        case "ynet":
        case "ynetnews":
            return "Ynet";

        case "jpost":
            return "The Jerusalem Post";

        case "bhol":
            return "בחדרי חרדים";

        case "walla":
        case "news":
        case "healthy":
            return "וואלה";

        case "n12":
            return "ערוץ 12";
            
        case "mako":
            if (url.includes("12/"))
                return "ערוץ 12";
            
            return "מאקו";
            
        case "mivzaklive":
            return "מבזק לייב";

        case "aurora-israel":
            return "Aurora";

        case "news08":
            return "חדשות 08";

        case "x":
            return "X (לשעבר טוויטר)";

        case "haaretz":
            return "הארץ";

        case "calcalist":
            return "כלכליסט";

        case "tip":
            return "חדשות טיפ";

        case "0404":
            return "חדשות 0404";

        case "maariv":
            return "מעריב";

        case "israelhayom":
            return "ישראל היום";

        case "hidabroot":
            return "הידברות";

        case "e-med":
            return "e-Med";

        case "m":
            return "News1";

        case "timesofisrael":
            return "The Times of Israel";

        case "yomyom":
            return "יום יום";

        case "hamal":
            return 'חמ"ל';

        case "drkids":
            return "דוקטור אפי";

        case "makorrishon":
            return "מקור ראשון";
            
        case "emess":
            return "אמס";
            
        case "103fm":
            return "103FM";

        case "ini":
            return "in-I";

        case "kore":
            return "כל רגע";

        case "algemeiner":
            return "The Algemeiner";

        case "kan":
            return "כאן 11";

        case "rinunim":
            return "רינונים";

        case "ice":
            return "ICE";

        case "hayadan":
            return "הידען";

        case "inn":
            return "ערוץ 7";

        case "shavvim":
            return "שווים";

        case "vlu":
            return "vlu - הדרך להצלחה";

        case "ntdtv":
            return "NTD";

        case "allisrael":
            return "Ali Israel";

        case "newshaifakrayot":
            return "חדש חיפה והקריות";

        case "ejewishphilanthropy":
            return "Jewish Philanthropy";

        case "1075":
            return "107.5 FM";

        case "carmelist":
            return "כרמליסט";

        case "be106":
            return "106 גדרה";

        case "haipo":
            return "תאגיד החדשות חיפה";

        case "zman":
            return "זמן ישראל";

        case "themarker":
            return "The Marker";
            
        case "video":
            if (url.includes("i24news"))
                return "i24News";
            
            return "וידאו";

        default:
            return domainName;
    }
}


const Audio = ({item}: { item: Item }) => {
    const {image, publishDate, author, audio} = item;


    return <div className="article-lower article-lower-audio">
        <div>
            <img src={image} alt="img"/>

            <div className="controls">
                <span>{publishDate}</span>
                <span>{author}</span>
            </div>
        </div>


        <ReactAudioPlayer src={audio} controls volume={0.7} className="audio-player"/>
    </div>
}


const Video = ({item}: { item: Item }) => {
    const {publishDate, author, video, url} = item;

    const Txt = () => {
        if (url.length === 0)
            return <span>{author}</span>;
        
        if (!author)
            return <span>{GetNewspaper(url)}</span>;
        
        return <span>{GetNewspaper(url)} | {author}</span>;

    }
    
    return <div className="article-lower article-lower-video">
        <div className="controls">
            <span>{publishDate}</span>
            <Txt/>
        </div>

        <ReactPlayer url={video} controls volume={0.7} className="video-player"/>
    </div>
}


const Image = ({item}: { item: Item }) => {
    const {image, publishDate, author} = item;

    return <div className="article-lower article-lower-image">
        <div>
            <img src={image} alt="img"/>

            <div className="controls">
                <span>{publishDate}</span>
                <span>{author}</span>
            </div>
        </div>
    </div>
}


const ArticleLower = ({item}: { item: Item }) => {
    const {title, image, publishDate, author} = item;

    return <div className="article-lower">
        <div className="controls">
            <span>{publishDate}</span>
            {/*<span>{author}</span>*/}
            <span>{item.url ? GetNewspaper(item.url) : item.author}</span>
        </div>

        <img src={image} alt={title}/>
    </div>
}


export const Article = ({item, i}: { item: Item, i: number }) => {
    const [modal, setModal] = useState<boolean>(false);
    const {title, url} = item;


    const open = () => {
        if (articleType() === "video")
            return

        if (item.modal)
            return setModal(true);


        if (url.length > 0)
            window.open(url, '_blank');
    }


    const articleType = (): ItemTypes => {
        if (!item.type)
            return "article"

        if (item.type === "audio" && item.audio)
            return "audio"

        if (item.type === "video" && item.video)
            return "video"
        
        if (item.type === "image" && item.image)
            return "image"

        return "article"
    }


    const Lower = () => {
        const type = articleType();

        if (type === "audio")
            return <Audio item={item}/>

        if (type === "video")
            return <Video item={item}/>
        
        return <ArticleLower item={item}/>
    }


    return <>
        <div className="article" key={i} onClick={open}>
            <div className="article-upper">
                <h3>{title}</h3>
                <span>{i + 1}</span>
            </div>

            <Lower/>
        </div>

        {modal && <ArticleModalComponent setModal={setModal}>
            {item}
        </ArticleModalComponent>}
    </>
}

