import {Client, Collection} from "../Collections/Types";


const SmallSteps: Client = {
    name: "עמותת צעדים קטנים",
    url: "https://www.littlesteps.org.il/",
    logo: "/other/little-steps.png",
    favicon: "/other/little-steps-favicon.png",
};


export const SmallSteps2024part1: Collection = {
    title: "עמותת צעדים קטנים",
    client: SmallSteps,
    items: [
        {
            url: "https://tip.co.il/%D7%90%D7%A8%D7%94%D7%91-%D7%94%D7%95%D7%A1%D7%A8%D7%94-%D7%9E%D7%92%D7%91%D7%9C%D7%AA-%D7%94%D7%92%D7%99%D7%9C-%D7%A2%D7%9C-%D7%A9%D7%99%D7%9E%D7%95%D7%A9-%D7%91%D7%AA%D7%A8%D7%95%D7%A4%D7%94/",
            title: "ארה\"ב: הוסרה מגבלת הגיל על שימוש בתרופה אלוידיס לחולי ניוון שרירים מסוג דושן",
            publishDate: "21/06/2024",
            image: "https://tip.co.il/wp-content/uploads/2024/06/%D7%98%D7%9C%D7%99-%D7%A7%D7%A4%D7%9C%D7%9F-768x512.jpg",
            author: "חדשות טיפ",
            type: "article"
        },
        {
            url: "https://www.mivzaklive.co.il/archives/798280",
            type: "article",
            publishDate: "15/04/2024",
            author: "מערכת חדשות מבזק לייב",
            title: "מחלה של בנים: מהי מחלת דושן – בקר וכמה חולים יש בישראל?",
            image: "https://i0.wp.com/www.mivzaklive.co.il/wp-content/uploads/2020/06/girl-holding-sidewalk-chalk.jpg?w=630&ssl=1",
        },
        {
            url: "https://vlu.co.il/article/1327",
            image: "https://vlu.co.il/articles_new/10336_tumb_750Xauto.jpg",
            title: "\"ישבתי בפסטיגל ובכיתי. היה לי תינוק בן שנה עם שעון חול מתקתק\"",
            author: "שרון רוטר",
            publishDate: "08/05/2024",
            type: "article"
        },
        {
            url: "https://shavvim.co.il/2024/06/13/%D7%9C%D7%9C%D7%9E%D7%95%D7%93-%D7%A2%D7%9C-%D7%A7%D7%91%D7%9C%D7%AA-%D7%94%D7%90%D7%97%D7%A8-%D7%93%D7%A8%D7%9A-%D7%9E%D7%A9%D7%97%D7%A7-%D7%A7%D7%9C%D7%A4%D7%99%D7%9D-%D7%9E%D7%99%D7%95/",
            title: "ללמוד על קבלת האחר – דרך משחק קלפים מיוחד",
            publishDate: "13/06/2024",
            image: "/other/img29.png",
            type: "article",
            author: "גיא וקנין"
        },
        {
            url: "https://www.bhol.co.il/news/1665981",
            title: "הוסרה מגבלת הגיל על שימוש בתרופה לחולי ניוון שרירים",
            publishDate: "21/06/2024",
            author: "אלי קליין",
            image: "https://storage.bhol.co.il/media/lt/1562665/gallery/268208_tumb_800X480.jpg",
            type: "article"
        },
        {
            url: "https://www.mivzaklive.co.il/archives/823949",
            title: "בשורה לחולי ניוון שרירים: הוסרה מגבלת הגיל לשימוש בתרופה אלוידיס",
            publishDate: "21/06/2024",
            author: "ארינאל מונק",
            image: "https://i0.wp.com/www.mivzaklive.co.il/wp-content/uploads/2024/06/WhatsApp-Image-2024-06-21-at-11.08.22.jpeg?w=800&ssl=1",
            type: "article"
        },
        {
            url: "https://shavvim.co.il/2024/06/23/%D7%91%D7%A9%D7%95%D7%A8%D7%94-%D7%9C%D7%97%D7%95%D7%9C%D7%99-%D7%94%D7%93%D7%95%D7%A9%D7%9F-%D7%96%D7%95-%D7%94%D7%AA%D7%A8%D7%95%D7%A4%D7%94-%D7%A9%D7%A7%D7%99%D7%91%D7%9C%D7%94-%D7%90%D7%99%D7%A9/",
            title: "בשורה לחולי הדושן: זו התרופה שקיבלה אישור מה-FDA",
            publishDate: "23/06/2024",
            author: "מערכת שווים",
            image: "/other/img28.png",
            type: "article"
        },
        {
            url: "https://doctorsonly.co.il/2024/06/319167/",
            title: "ה-FDA הסיר את מגבלת הגיל על התרופה אלוידיס לחולי דושן",
            author: "מערכת דוקטורס אונלי",
            publishDate: "24/06/2024",
            image: "https://cdn.doctorsonly.co.il/2024/06/shutterstock_219539785-992x558.jpg",
            type: "article"
        },
        {
            url: "https://www.e-med.co.il/specialties/dermatology/2024/06/04/582509",
            title: "כיצד לקדם מחקר במחלות נדירות? כנס של משרד הבריאות בנושא התקיים השבוע במתכונת מקוונת",
            publishDate: "04/06/2024",
            image: "/other/img2.png",
            type: "article",
            author: "מערכת e-Med"
        },
        {
            url: "https://www.ynet.co.il/activism/article/rkoklqfqr?utm_source=ynet.app.android&utm_medium=social&utm_campaign=whatsapp&utm_term=rkoklqfqr&utm_content=Header",
            title: "\"המומחים לא הצליחו לאבחן את בני, אז הקמתי עמותה לקידום המודעות למחלה\"",
            publishDate: "27/05/2024",
            author: "אופיר האוזמן",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/04/25/HJs0FUDZ0/HJs0FUDZ0_0_483_1158_869_0_x-large.jpg",
            type: "article"
        }
    ]
};


export const SmallSteps2025: Collection = {
    title: "עמותת צעדים קטנים - סל הבריאות 2025",
    client: SmallSteps,
    items: [
        {
            url: "",
            image: "/other/img33.png",
            title: "סל הבריאות: נכנסה תרופה למחלת ניוון השרירים-דושן",
            publishDate: "12/02/2025",
            author: "קול העיר בני ברק",
            modal: [
                `עמותת 'צעדים קטנים' לליווי מאובחני דושן-בקר ומשפחותיהם, מודה לחברי ועדת הסל על פריצת הדרך ועל הכנסת הסטרואידים - אגאמרה (Agamree), שפותחו באופן ייעודי למחלה ואינם גורמים לתופעות הלוואי הקשות שנגרמות מסטרואידים אחרים, ואשר מהן כל הורה חרד (בהם: אוסטאופורוזיס בגיל צעיר, התקפי זעם, השמנה, פגיעה בהליך הצמיחה לגובה ועוד).
דושן-בקר היא מחלת ניוון שרירים רב-מערכתית ופרוגרסיבית, אשר בלימת התפשטותה והחמרתה מחייבת טיפול שמשפר את איכות חיי המאובחנים, לצד שילוב אסטרטגיות של טיפול תרופתי.
אגאמרה הם סוג של סטרואידים שמפחיתים את התהליך הדלקתי בגוף, הנגרם בשל המחלה. התוופה משפרת את איכות חיי החולה, וכאמור, מפחיתה את תופעות הלוואי בהשוואה לסטרואידים אחרים. זו למעשה התרופה היחידה לדושן שאושרה הן על ידי ה-FDA האמריקאי, הן ע"י ה-EMA האירופאי, ולאחרונה גם באנגליה.

טלי קפלן, מנכ"לית עמותת צעדים קטנים אומרת: "שברנו היום את מחסום ועדת הסל עם תרופה ראשונה לדושן, אשר משפרת באופן משמעותי את איכות חיי החולים, ואשר צריכה להילקח כל החיים. אני מודה לפרופ' בן יהודה ולחברי ועדת הסל ומלאת תקווה שבשנים הבאות נצליח להכניס לסל תרופות באסטרטגיות טיפול נוספות, ובכך לעצור את התקדמות המחלה ולהאריך את תוחלת חיי ילדינו המאובחנים עם המחלה".`,

                {type: "image", data: "/other/img33.png"},
            ]
        },
        {
            url: "",
            image: "/other/img34.png",
            author: "יתד נאמן",
            publishDate: "11/02/2025",
            title: "יותר חיסונים וטיפולים מונעים פחות תרופות למחלות",
            modal: [
                {
                    type: "subtitle", data: `סל של בשורות, ובצידן אכזבות: עדכון סל התרופות ל2025 מביא עימו לא מעט בשורות משמחות אך בצידן גם אכזבות. ושוב, תרופות ההרזיה נותרו בחוץ... 
               
                כניסתן של 117 תרופות וטכנולוגיות חדשות לסל, תקל מאוד על חייהם של מאות אלפי אנשים, ביניהם חולי יתר לחץ דם ריאתי, שזוכים לנשום לרווחה, והורים לתינוקות שיקבלו את החיסון נגד נגיף ה-RSV המאיים.
                 עבור מעשנים לשעבר, אושרה בדיקת סקר סי.טי לגילוי מוקדם של סרטן ריאות. חולי סוכרת זכאים מעתה למערכת לבלב מלאכותי בלי הגבלת גיל. 
                 ויש גם בשורה להורים לבני נוער הזקוקים לטיפולי שיניים בהרדמה מלאה אלה שנותרו בחוץ, ימשיכו להתייסר, בציפייה שהעדכון הבא ייטיב עימם יותר.`
                },

                `החצי הריק מול החצי המלא: זה היה צפוי. בתקציב של 650 מיליון שקל, אי אפשר להכניס הכל. לוועדת הסל הוגשו כמעט 600 תרופות וטכנולוגיות חדשות בשווי של 4 מיליארד שקל, מתוכן נבחרו 117 בלבד. תרופות ההרזיה, גם השנה, נותרו בחוץ. אבל קשה להתמרמר כאשר טיפולים קריטיים ומצילי חיים עומדים על הפרק. "הצלחנו לכלול השנה בסל התרופות מגוון רחב של תרופות וטכנולוגיות חדשניות לטיפול במחלות קשות, עם דגש מיוחד לאבחון מוקדם ורפואה מונעת, לדוגמא בדיקות סקר ומעקב רציף וחיסונים", אמרה יו"ר ועדת הסל פרופ' דינה בן יהודה. "עם זאת, לצד ההישגים, זהו יום מורכב, ישנם חולים שטכנולוגיות חיוניות עבורם לא הצליחו להיכלל בסל והלב שלי כואב על כך שלא ניתן היה לתת מענה לכל אחד מהם. אני כולי תקווה שבשנה הבאה נוכל להוסיף פתרונות רפואיים נוספים ולהבטיח שסל הבריאות בישראל ימשיך לתת מענה משמעותי ואיכותי לציבור בישראל".
                 
                 גולת הכותרת של העדכון הנוכחי היא  נגיף ה RSV שנכנס לסל, לראשונה עבור כלל התינוקות! שפעת היא האיום החורפי היותר מוכר, אבל נגיף ה RSV  נפוץ הרבה יותר - ואחראי לחלק גדול מהאשפוזים של תינוקות ופעוטות בגלל דלקת ריאות. הנגיף מסוכן במיוחד לפגים ולתינוקות קטנים, ולמרבה החסד - בשנים האחרונות קיים חיסון כנגדו. אלא שעד עתה, הזכאות לחיסון נגד RSV במסגרת הסל ניתנה רק לאוכלוסיית הפגים שנמצאת בסיכון גבוה, ורק לאלה שענו להתוויות. עלות הכנסת החיסון לסל – 109 מיליון שקל עבור מספר מוערך של 164 אלף תינוקות. 
                 
                 בעקבות עדכון הסל, החיסון יינתן לכל התינוקות לפני עונת החורף הראשונה לחייהם, ולתינוקות בסיכון -גם בשנה השנייה. באיגוד רופאי הילדים הישראלי שנאבק להכניס את החיסון לסל לכלל התינוקות, בירכו על הבשורה והזכירו כי החיסון מסייע להפחתת התחלואה ועלויות האשפוזים. עם זאת, באיגוד הישראלי לרפואה פנימית הביעו אכזבה מכך שהחיסון נגד RSV לא אושר למבוגרים.`,

                {type: "subtitle", data: "לראשונה: תרופה לטיפול בילדי דושן"},

                `עוד בתחום רפואה מונעת וגילוי מוקדם: בדיקת סקר למעשנים, לגילוי מוקדם של סרטן הריאה. מדובר בבדיקת  CTבקרינה נמוכה, שמאפשרת גילוי מוקדם של סרטן ריאה כאשר סיכויי הריפוי גבוהים. הבדיקה מתמקדת בקבוצה בסיכון גבוה - מעשנים ומעשנים לשעבר, ולאחר שנערך פיילוט מוצלח, הוחלט להכניסה לסל, בשלב זה עבור בני 65 - 75 בלבד. העלות - 11.7 מיליון שקל, עבור כ 17 אלף איש.

דר שני שילה מנכ"לית העמותה הישראלית לסרטן הריאה, ציינה כי מדובר בהחלטה היסטורית. "זהו רגע מרגש עבורנו בעמותה, שכן מדובר במאבק של שנים רבות. לפני 12 שנה, בעלי אלעד אובחן עם סרטן ריאה, ולצערנו הוא נפטר לפני כשנתיים. הטרגדיה האישית היא זו שהניעה אותי להקים את העמותה ולפעול לאורך שנים להכללת בדיקת הסקר שיכולה להציל חיים. גילוי מוקדם של סרטן ריאה מעלה משמעותית את סיכויי ההחלמה. עם זאת, חשוב להמשיך ולפעול להרחבת הקריטריונים לבדיקה, כך שתכלול גם גילאים נוספים שבסיכון. אנו נמשיך להיאבק למען מטרה חשובה". הוריהם של ילדים חולי דושן, נשמו לרווחה עם היוודע הבשורה: אחרי 10שנים רצופות של דחייה, נכנסת התרופה הראשונה לסל לטיפול במחלת ניוון השרירים הנדירה. התרופה Agamree שפותחה באופן ייעודי למחלה, מבוססת על סטרואיד שמפחית את התהליך הדלקתי הנגרם בשל המחלה ומשפר את איכות החיים של החולה בלי לגרום לתופעות לוואי קשות שנגרמות מסטרואידים אחרים.
התרופה נכנסת לסל בעלות של 20.6 מיליון שקל עבור 161 חולים. בעמותת "צעדים קטנים" לליווי מאובחני דושן-בקר ומשפחותיהם, סיפרו כי עשרות משפחות יזמו פתיחת קבוצה עם תפילות משותפות סביב דיוני הסל. "שברנו היום את מחסום וועדת הסל עם תרופה ראשונה לדושן, שצריכה להילקח כל החיים", אומרת טלי קפלן מנכ"לית העמותה. "אני מלאי תקווה שבשנים הבאות נצליח להכניס לסל תרופות באסטרטגיות טיפול נוספות".`,

                {type: "image", data: "/other/img34.png", max: true},


            ]
        },
        {
            url: "",
            image: "/other/img35.jpg",
            author: "מיטל יסעור בית-אור",
            publishDate: "11/02/2025",
            title: "יותר לחיסונים, פחות לסרטן",
            modal: [
                {type: "image", data: "/other/img35.jpg", max: true},
            ]
        },
        {
            url: "https://www.mako.co.il/health-news/local/Article-b0bb88dbfa29491027.htm",
            title: "היעדר נתונים רשמיים, מחסור במומחים בגנטיקה ואבחון מאוחר בכל הנוגע לחולים במחלות דושן-בקר",
            image: "https://img.mako.co.il/2025/01/23/shutterstock_2575485997_re_autoOrient_w.jpg",
            publishDate: "23/01/2025",
        },
        {
            url: "https://video.i24news.tv/he/0e8ab8d2-dd73-44ce-a563-bcf82757ad45/content/6367516107112",
            publishDate: "22/01/2025",
            video: "/video/i24.mp4",
            title: "למען הילדים",
            type: "video",
            image: ""
        },
        {
            url: "https://doctorsonly.co.il/2025/01/336909/",
            title: "כמה חולי דושן-בקר יש בישראל? תלוי את מי שואלים",
            image: "/other/img36.png",
            publishDate: "26/01/2025",
        },
        {
            url: "https://apchi.co.il/אין-נתונים-אין-פתרונות-מערכת-הבריאות-מ/",
            title: "אין נתונים, אין פתרונות: מערכת הבריאות מפקירה את חולי דושן-בקר",
            image: "https://apchi.co.il/wp-content/uploads/2025/01/WhatsApp-Image-2025-01-26-at-09.10.15.jpeg",
            publishDate: "26/01/2025",
        },
        {
            url: "https://ini.co.il/archives/5145",
            title: "למה יש עמימות בישראל בענין מספר החולים בניוון שרירים דושן-בקר?!",
            image: "/other/img30.png",
            publishDate: "26/01/2025",
        },
        {
            url: "https://www.mako.co.il/health-illnesses-and-medicines/Article-13bca3ff8bac491026.htm",
            image: "https://img.mako.co.il/2025/02/03/HGJVG666_autoOrient_w.jpg",
            title: '"כמו הורי החטופים, גם אני נלחמת על חיי הבן שלי"',
            publishDate: "03/02/2025",
        },
        {
            url: "https://www.ynet.co.il/health/article/rkqhkbpuyl?utm_source=ynet.app.android&utm_medium=social&utm_campaign=general_share&utm_term=rkqhkbpuyl&utm_content=Header",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver6/crop_images/2025/02/06/rJdNGXfK1x/rJdNGXfK1x_0_0_1000_667_0_x-large.jpg",
            title: "סל הבריאות: יותר חיסונים, פחות תקציב לסרטן. בחוץ: זריקות הרזיה וטיפול לאלצהיימר",
            publishDate: "06/02/2025"
        },
        {
            url: "https://www.israelhayom.co.il/health/article/17311933",
            title: '"אני חי עם פצצה מתקתקת בראש": החולים שנותרו ללא מענה בסל התרופות - ואלו שכן',
            image: "https://www.israelhayom.co.il/wp-content/uploads/2023/01/29/1419726692047_b-960x640.jpg",
            publishDate: "06/02/2025"
        },
        {
            url: "https://www.bhol.co.il/news/1685827",
            title: "שהחיינו! סוף סוף תרופה ראשונה למחלת ניוון שרירים נכנסה לסל הבריאות",
            image: "https://storage.bhol.co.il/media/lt/1652619/gallery/682822_tumb_800X480.jpg",
            publishDate: "06/02/2025"
        },
        {
            url: "https://shavvim.co.il/2025/02/06/%D7%90%D7%97%D7%A8%D7%99-10-%D7%A9%D7%A0%D7%99%D7%9D-%D7%A0%D7%9B%D7%A0%D7%A1%D7%94-%D7%9C%D7%A1%D7%9C-%D7%AA%D7%A8%D7%95%D7%A4%D7%94-%D7%9C%D7%99%D7%9C%D7%93%D7%99%D7%9D-%D7%94%D7%97%D7%95%D7%9C/",
            title: "אחרי 10 שנים: נכנסה לסל תרופה לילדים החולים בניוון שרירים מסוג דושן",
            image: "/other/img32.png",
            publishDate: "06/02/2025"   
        },
        {
            url: "https://www.emess.co.il/radio/1667051",
            title: "בשורה אדירה: זו התרופה שנוספה לסל התרופות",
            image: "https://www.emess.co.il/resize/?width=800&height=450&url=/uploads/2025/01/%D7%AA%D7%9E%D7%95%D7%A0%D7%95%D7%AA-%D7%90%D7%9E%D7%A1-96-1024x576.png",
            publishDate: "06/02/2025"
        },
        {
            url: "https://www.ice.co.il/health/news/article/1050432",
            title: "אחרי 10 שנים של דחיות: התרופה למחלת דושן-בקר הוכנסה לסל",
            image: "https://img.ice.co.il/giflib/news/rsPhoto/sz_503/rsz_615_346_shutterstock_2479150159.jpg",
            publishDate: "07/02/2025"
        },
        {
            url: "https://www.e-med.co.il/specialties/family-medicine/2025/02/06/588119",
            title: "תרופה ראשונה למחלת ניוון השרירים דושן נכנסה לסל התרופות",
            image: "/other/img31.png",
            publishDate: "06/02/2025"
        },
        {
            url: "https://www.i24news.tv/he/news/news/health/artc-5c9018e9",
            title: '117 תרופות וטכנולוגיות - בעלות 650 מיליון: המלצות ועדת הסל',
            image: "https://cdn.i24news.tv/uploads/98/59/f4/77/44/9e/4c/30/4c/0a/ce/ab/97/47/c2/c0/9859f477449e4c304c0aceab9747c2c0.jpg?width=1000&type=webp",
            publishDate: "06/02/2025"
        },
        {
            url: "https://www.maariv.co.il/news/health/article-1170902",
            title: 'רופאים וחולים זועמים על תרופות שלא הוכנסו לסל: "מי שאין לו כסף - ימות"',
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/961495",
            publishDate: "07/02/2025"
        },
        {
            url: "https://www.ynet.co.il/health/article/b1upf0rkjg",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver6/crop_images/2025/02/10/r1Jh0gDYJl/r1Jh0gDYJl_14_108_740_555_0_x-large.jpg",
            title: "מחיר חיי ילד חולה דושן: התרופה שתמומן, וזו שעולה 12 מיליון - ולא אושרה",
            publishDate: "10/02/2025"
        },
    ]
};

